import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';

import { FavMenuService } from '@app/shared/services/fav-menu.service';

@Component({
  selector: 'app-favorites',
  templateUrl: './favorites.component.html',
  styleUrls: ['./favorites.component.scss'],
})
export class FavoritesComponent implements OnInit, OnDestroy {
  userMenu: any = [];

  roleMenu: any = [];

  isVisible = false;

  isFirstActive = true;

  private subscriptions: Subscription = new Subscription();

  constructor(private favMenuService: FavMenuService) {
    this.subscriptions.add(
      this.favMenuService.updatedMenuItems.subscribe((res: any) => {
        if (res) {
          this.getFavMenu();
        }
      })
    );

    this.subscriptions.add(
      this.favMenuService.isFavMenuOpen$.subscribe((isMenuOpen: any) => {
        this.isVisible = isMenuOpen || false;
      })
    );
  }

  @HostListener('document:click', ['$event'])
  closeDiv(): void {
    if (this.isVisible) {
      this.isVisible = false;
    }
  }

  getFavMenu(): void {
    this.favMenuService.getFavMenu().subscribe((res: any) => {
      this.userMenu = res.USER_FAVORITE;
      this.roleMenu = res.ROLE_FAVORITE;
    });
  }

  ngOnInit(): void {
    this.getFavMenu();
  }

  toggleFavMenu(event?: Event): void {
    event?.stopPropagation();
    this.isVisible = !this.isVisible;
  }

  activateFirst(): void {
    this.isFirstActive = true;
  }

  activateSecond(): void {
    this.isFirstActive = false;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
