import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MetricPermissionService {
  hasAddMetric(): boolean {
    const permissions: any = sessionStorage.getItem('permissions');

    if (permissions?.includes('Metric Add')) {
      return true;
    }

    return false;
  }

  hasAddMetricVersion(): boolean {
    const permissions: any = sessionStorage.getItem('permissions');

    if (permissions?.includes('Metric Edit')) {
      return true;
    }

    return false;
  }

  hasEditMetricVersion(): boolean {
    const permissions: any = sessionStorage.getItem('permissions');

    if (permissions?.includes('Metric Edit')) {
      return true;
    }

    return false;
  }

  hasPublishMetricVersion(): boolean {
    const permissions: any = sessionStorage.getItem('permissions');

    if (permissions?.includes('Metric Edit')) {
      return true;
    }

    return false;
  }

  hasRevokeMetricVersion(): boolean {
    const permissions: any = sessionStorage.getItem('permissions');

    if (permissions?.includes('Metric Edit')) {
      return true;
    }

    return false;
  }
}
