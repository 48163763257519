import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { MenuPermissionsService } from '../services/menu-permissions.service';
import { LayoutService } from '@app/layout/services/layout.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  model: any[] = [
    {
      items: [
        {
          label: 'SLM',
          icon: 'domain',
          id: 'SEL_SLM',
          items: [
            {
              label: 'MODULES.SLM.METRICS',
              icon: 'analytics',
              id: 'SEL_SLM_METRICS',
              routerLink: ['/slm/metrics'],
              requiredPermission: 'Metric View',
            },
            {
              label: 'MODULES.SLM.CONTRACTS',
              icon: 'task',
              id: 'SEL_SLM_CONTRACTS',
              routerLink: ['/slm/contracts'],
              requiredPermission: 'Contract View',
            },
            {
              label: 'MODULES.SLM.CATALOG',
              icon: 'import_contacts',
              id: 'SEL_SLM_CATALOG',
              routerLink: ['/slm/catalog'],
              requiredPermission: 'Metric catalog View',
            },
            {
              label: 'MODULES.SLM.ORGANIZATIONS',
              icon: 'account_tree',
              id: 'SEL_SLM_ORGANIZATIONS',
              routerLink: ['/slm/organizations'],
              requiredPermission: 'Organizations View',
            },
            {
              label: 'MODULES.SLM.METRIC_CONNECTOR',
              icon: 'cable',
              id: 'SEL_SLM_CONNECTORS',
              routerLink: ['/slm/metric-connectors'],
              requiredPermission: 'Metric connector View',
            },
            {
              label: 'MODULES.SLM.COMPILANCE_REPORT',
              icon: 'inventory',
              id: 'SEL_SLM_COMPLIANCE',
              routerLink: ['/slm/compliance-report'],
              requiredPermission: 'Reports View',
            },
            {
              label: 'MODULES.SLM.PENALTIES',
              icon: 'euro',
              id: 'SEL_SLM_PENALTIES',
              tags: 'SIDENAV.COMING_SOON',
              disabled: true,
            },
          ],
        },
        {
          label: 'MODULES.REPORTS.REPORTS',
          icon: 'assignment',
          id: 'SEL_REPORT',
          items: [
            {
              label: 'MODULES.REPORTS.VIEW_REPORTS',
              icon: 'visibility',
              id: 'SEL_VIEW_REPORTS',
              routerLink: ['/reports/view-reports'],
              requiredPermission: 'Reports View',
            },
            {
              label: 'SOC',
              icon: 'encrypted',
              id: 'SEL_SOC',
              routerLink: ['/reports/soc'],
            },
            {
              label: 'MODULES.REPORTS.DASHBOARD',
              icon: 'dashboard',
              id: 'SEL_DASHBOARD',
              tags: 'SIDENAV.COMING_SOON',
              disabled: true,
            },
            {
              label: 'MODULES.DATA.ADJUSTMENTS',
              icon: 'tune',
              id: 'SEL_ADJUSTEMENTS',
              tags: 'SIDENAV.COMING_SOON',
              disabled: true,
            },
          ],
        },
        {
          label: 'MODULES.DATA.DATA',
          icon: 'folder',
          id: 'SEL_DATA',
          items: [
            {
              label: 'MODULES.DATA.HIERARCHIES.LABEL',
              icon: 'analytics',
              id: 'SEL_DATA_VIEW_HIERARCHY',
              routerLink: ['data/hierarchies'],
              requiredPermission: 'Hierarchy View',
            },
            {
              label: 'MODULES.DATA.UPLOADS',
              icon: 'upload',
              id: 'SEL_UPLOADS',
              routerLink: ['data/upload'],
              requiredPermission: 'Uploads View',
            },
            {
              label: 'MODULES.DATA.CALCULATIONS.LABEL',
              icon: 'calculate',
              id: 'SEL_CALCULATIONS',
              routerLink: ['data/calculations'],
              requiredPermission: 'Calculations View',
            },
            {
              label: 'MODULES.DATA.VIEW_DATA',
              icon: 'visibility',
              id: 'SEL_VIEW_DATA',
              tags: 'SIDENAV.COMING_SOON',
              disabled: true,
            },
            {
              label: 'MODULES.DATA.CONNECTORS',
              icon: 'cable',
              id: 'SEL_CONNECTORS',
              tags: 'SIDENAV.COMING_SOON',
              disabled: true,
            },
            {
              label: 'MODULES.DATA.STRUCTURES.STRUCTURES',
              icon: 'schema',
              id: 'SEL_STRUCTURES',
              tags: 'SIDENAV.COMING_SOON',
              disabled: true,
            },
          ],
        },
        {
          label: 'MODULES.STATUS.STATUS',
          icon: 'checklist',
          id: 'SEL_STATUS',
          items: [
            {
              label: 'MODULES.STATUS.CALCULATION_STATUS',
              icon: 'monitoring',
              id: 'SEL_CALCULATIONS_STATUS',
              tags: 'SIDENAV.COMING_SOON',
              disabled: true,
            },
            {
              label: 'MODULES.STATUS.APPROVALS',
              icon: 'task_alt',
              id: 'SEL_APPROVALS',
              tags: 'SIDENAV.COMING_SOON',
              disabled: true,
            },
          ],
        },
        {
          label: 'MODULES.DISTRIBUTIONS.DISTRIBUTIONS',
          icon: 'location_searching',
          id: 'SEL_DISTRIBUTION',
          items: [
            {
              label: 'MODULES.DISTRIBUTIONS.VIEW_DISTRIBUTIONS.LABEL',
              icon: 'file_copy',
              id: 'SEL_VIEW_DISTRIBUTION_TEMPLATES',
              routerLink: ['/distributions/view-distributions'],
              requiredPermission: 'Distributions Admin View',
            },
            {
              label: 'MODULES.DISTRIBUTIONS.DISTRIBUTE.LABEL',
              icon: 'description',
              id: 'SEL_DISTRIBUTION_DISTRIBUTIONS',
              routerLink: ['/distributions/distribute'],
              requiredPermission: 'Distributions View',
            },
            {
              label: 'MODULES.DISTRIBUTIONS.TEMPLATES.LABEL',
              icon: 'backup_table',
              id: 'SEL_DISTRIBUTION_TEMPLATE',
              routerLink: ['/distributions/templates'],
              requiredPermission: 'Distributions Admin View',
            },
            {
              label: 'MODULES.DISTRIBUTIONS.HISTORY.LABEL',
              icon: 'history',
              id: 'SEL_DISTRIBUTION_TEMPLATE',
              routerLink: ['/distributions/history'],
              requiredPermission: 'Distributions View',
            },
          ],
        },
      ],
    },
  ];

  visualDesigner: any = {
    label: 'MODULES.VISUAL_DESIGNER.LABEL',
    // icon: 'bolt',
    tags: 'SIDENAV.DEMO',
    id: 'SEL_VISUAL_DESIGNER',
    routerLink: ['/visual-designer'],
  };

  constructor(
    public layoutService: LayoutService,
    private router: Router,
    private menuPermission: MenuPermissionsService
  ) {}

  redirectToHome(): void {
    this.router.navigate(['/welcome']);
  }

  addVisualDesignerToMenu(): void {
    if (this.menuPermission.isNotTSLA()) {
      this.model[0].items.push(this.visualDesigner);
    }
  }

  ngOnInit(): void {
    this.addVisualDesignerToMenu();
  }
}
