<button
  class="p-link p-button-text p-1"
  (click)="
    changeTheme(
      layoutService.config.colorScheme === 'light'
        ? 'md-dark-indigo'
        : 'md-light-indigo',
      layoutService.config.colorScheme === 'light' ? 'dark' : 'light'
    )
  "
>
  <i
    class="icon-size-color"
    [ngClass]="
      layoutService.config.colorScheme === 'light' ? 'pi pi-moon' : 'pi pi-sun'
    "
    pTooltip="{{
      (layoutService.config.colorScheme === 'light'
        ? 'HEADER.DARK_THEME'
        : 'HEADER.LIGHT_THEME'
      ) | translate
    }}"
    tooltipPosition="bottom"
  ></i>
</button>
