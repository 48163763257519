<div class="container-fluid">
  <div class="row">
    <div class="col-6">
      <button pButton type="button" label="{{ 'MODULES.METRICS.ADD' | translate }}"
        [disabled]="!metricPermission.hasAddMetric()" class="p-button" (click)="openCreateMetricForm()"></button>
    </div>
    <div class="col-6 text-right pb-0">
      <button pButton type="button" class="p-button mb-0" label="{{ 'MODULES.METRICS.CLEAR_FILTER' | translate }}"
        (click)="clearGrid()" [disabled]="!haveFilters"></button>
    </div>
  </div>

  <div class="row">
    <ng-container *ngIf="headers && headers.length > 0">
      <ag-grid-angular class="ag-theme-balham" style="width: 100%; height: calc(100vh - 140px)" [columnDefs]="headers"
        [gridOptions]="gridOptions" (cellClicked)="onCellClick($event)" (gridReady)="onGridReady($event)"
        (sortChanged)="onSortChange()" (filterModified)="onFilterModified($event)" #agGrid>
      </ag-grid-angular>
    </ng-container>
  </div>

  <!-- view metric details -->
  <p-sidebar [(visible)]="isMetricViewEnabled" position="right" styleClass="p-sidebar-lg right-sidebar-view"
    *ngIf="isMetricViewEnabled" (onHide)="close()">
    <app-view-metric (closeClicked)="isMetricViewEnabled = $event"></app-view-metric>
  </p-sidebar>

  <!-- create new metric -->
  <p-sidebar [(visible)]="isCreateMetricEnabled" position="right" styleClass="p-sidebar-lg right-sidebar-view"
    [dismissible]="false" [closeOnEscape]="false" *ngIf="isCreateMetricEnabled">
    <app-add-metric (closeClicked)="isCreateMetricEnabled = $event"></app-add-metric>
  </p-sidebar>
</div>