<div class="right-sidebar-header">
    <h5 class="px-4 fw-bold d-inline" translate>MODULES.VIEW_METRICS.DETAILS</h5>
    <p-divider styleClass="mb-0"></p-divider>
</div>

<div class="px-4">
    <div class="row mt-2">
        <!-- Metric ID-->
        <div class="col-3 col-sm-3 fw-bold" translate>
            MODULES.VIEW_METRICS.CATALOG_ID
        </div>
        <div class="col-9 col-sm-9">
            {{ viewData?.metricNo }}
            <p-skeleton *ngIf="!viewData?.metricNo"></p-skeleton>
        </div>

        <!-- Metric name -->
        <div class="col-3 col-sm-3 fw-bold" translate>
            MODULES.VIEW_METRICS.NAME
        </div>
        <div class="col-9 col-sm-9">
            {{ viewData?.name }}
            <p-skeleton *ngIf="!viewData?.name"></p-skeleton>
        </div>

        <!-- Metric Status -->
        <div class="col-3 col-sm-3 fw-bold" translate>
            MODULES.VIEW_METRICS.METRIC_STATUS
        </div>
        <div class="col-9 col-sm-9">
            <span *ngIf="viewData?.status" [ngClass]="
            viewData?.status === 'Published'
              ? 'success-chip'
              : viewData?.status === 'Draft'
              ? 'warning-chip'
              : 'danger-chip'
          ">
                {{ viewData?.status }}
            </span>
            <p-skeleton *ngIf="!viewData?.status"></p-skeleton>
        </div>

        <!--Description-->
        <div class="col-3 col-sm-3 fw-bold" translate>
            MODULES.VIEW_METRICS.DESCRIPTION
        </div>
        <div class="col-9 col-sm-9">
            {{ viewData?.description }}
            <p-skeleton *ngIf="!viewData?.description"></p-skeleton>
        </div>

        <!--Metric Connector Name-->
        <div class="col-3 col-sm-3 fw-bold" translate>
            MODULES.METRICS.METRIC_CONNECTOR
        </div>
        <div class="col-9 col-sm-9">
            {{ viewData?.metricConnector }}
            <p-skeleton *ngIf="!viewData?.metricConnector"></p-skeleton>
        </div>

        <!--Offering-->
        <div class="col-3 col-sm-3 fw-bold" translate>
            MODULES.VIEW_METRICS.OFFERING
        </div>
        <div class="col-9 col-sm-9">
            {{ viewData?.businessOffering }}
            <p-skeleton *ngIf="!viewData?.businessOffering"></p-skeleton>
        </div>

        <!--Period-->
        <div class="col-3 col-sm-3 fw-bold" translate>
            MODULES.VIEW_METRICS.PERIOD
        </div>
        <div class="col-9 col-sm-9">
            {{ viewData?.periodName }}
            <p-skeleton *ngIf="!viewData?.periodName"></p-skeleton>
        </div>

        <!--Statement-->
        <div class="col-3 col-sm-3 fw-bold" translate>
            MODULES.VIEW_METRICS.STATEMENT
        </div>
        <div class="col-9 col-sm-9">
            {{ viewData?.statement }}
            <p-skeleton *ngIf="!viewData?.statement"></p-skeleton>
        </div>
        <!-- Condition -->
        <div class="col-3 col-sm-3 fw-bold" translate>
            MODULES.VIEW_METRICS.CONDITION
        </div>
        <div class="col-9 col-sm-9">
            {{ viewData?.conditionName }}
            <p-skeleton *ngIf="!viewData?.conditionName"></p-skeleton>

        </div>
        <!-- Format -->
        <div class="col-3 col-sm-3 fw-bold" translate>
            MODULES.VIEW_METRICS.FORMAT
        </div>
        <div class="col-9 col-sm-9">
            {{ viewData?.targetFormatName }}
            <p-skeleton *ngIf="!viewData?.targetFormatName"></p-skeleton>
        </div>
        <!-- Sub-format -->
        <div class="col-3 col-sm-3 fw-bold" translate>
            MODULES.VIEW_METRICS.SUB_FORMAT
        </div>
        <div class="col-9 col-sm-9">
            {{ viewData?.formatName }}
            <p-skeleton *ngIf="!viewData?.formatName"></p-skeleton>
        </div>

    </div>
</div>

<h5 class="mt-4 px-4" translate>MODULES.VIEW_METRICS.VERSION_DETAILS</h5>
<p-divider></p-divider>

<div class="px-4">
    <div class="row mt-2">
        <div class="col-12 col-sm-12">

            <ng-container *ngIf="viewVersionList.length">
                <button pButton type="button" class="w-full p-button-outlined custom-btn-outline"
                    (click)="op.toggle($event)">
                    <div class="w-100 text-center">
                        <span class="pe-2">{{ selectedVersion ? selectedVersion.versionName
                            : viewVersionList[0].versionName }}
                        </span>
                        <span [ngClass]="selectedVersion.status === 'Published' ? 'success-chip'
                : selectedVersion?.status === 'Draft' ? 'warning-chip' : 'danger-chip'">
                            {{ selectedVersion ? selectedVersion.status : viewVersionList[0].status }}
                        </span>
                    </div>
                    <i class="pi pi-angle-down"></i>
                </button>

                <p-overlayPanel #op [showCloseIcon]="true" styleClass="custom-overlay">
                    <ng-template pTemplate>

                        <!-- status filter -->
                        <form [formGroup]="createMetricForm">
                            <div class="mb-3">
                                <span class="fw-bold pe-3 ps-2 align-middle"
                                    translate>MODULES.VIEW_METRICS.FILTER_BY</span>
                                <ng-container formArrayName="statusFilter"
                                    *ngFor="let check of statusList; let i = index">
                                    <p-checkbox [label]="check.label" [binary]="true" class="align-middle px-2"
                                        [labelStyleClass]="check.class" [formControlName]="i"
                                        (onChange)="applyStatusFilter($event, check.label)">
                                    </p-checkbox>
                                </ng-container>
                            </div>
                        </form>

                        <p-table #paginationReset styleClass="p-datatable-sm" [value]="filteredVersion"
                            selectionMode="single" [(selection)]="selectedVersion" (onRowSelect)="onClosePanel($event)"
                            (onRowUnselect)="onClosePanel($event)" [paginator]="true" [rows]="10"
                            [showCurrentPageReport]="true"
                            currentPageReportTemplate="{{'MODULES.PAGINATION.SHOWING' | translate }} {first} {{'MODULES.PAGINATION.TO' | translate }} {last} {{'MODULES.PAGINATION.OF' | translate }} {{filteredVersion.length}} {{'MODULES.PAGINATION.ENTRIES' | translate }}"
                            [scrollHeight]="'calc(100vh - 180px)'">

                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="width: 110px;" translate>MODULES.VIEW_METRICS.VERSION_NAME</th>
                                    <th style="width: 90px;" translate>MODULES.METRICS.STATUS</th>
                                    <th style="width: 100px;" translate>MODULES.VIEW_METRICS.START_DATE</th>
                                    <th style="width: 100px;" translate>MODULES.VIEW_METRICS.END_DATE</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-rowData let-version>
                                <tr [pSelectableRow]="rowData">
                                    <td>{{version.versionName}}</td>
                                    <td>
                                        <span [ngClass]="version.status === 'Published' ? 'success-chip'
                      : version.status === 'Draft' ? 'warning-chip' : 'danger-chip'">
                                            {{version.status}}
                                        </span>
                                    </td>
                                    <td>{{version.startDate | date : 'yyyy-MM-dd'}}</td>
                                    <td>{{version.endDate | date : 'yyyy-MM-dd'}}</td>
                                </tr>
                            </ng-template>
                            <!-- no records msg -->
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td class="text-center" [attr.colSpan]="4">{{ 'MODULES.EMPTY_TABLE_MSG' |
                                        translate}}</td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </ng-template>
                </p-overlayPanel>

            </ng-container>
            <p-skeleton *ngIf="!viewVersionList.length"></p-skeleton>
        </div>

        <div class="col-3 col-sm-3 fw-bold" translate>MODULES.VIEW_METRICS.VERSION_NAME</div>
        <div class="col-9 col-sm-9">
            <span *ngIf="createMetricForm.get('versionName')?.value">
                {{createMetricForm.get('versionName')?.value}}
            </span>
            <p-skeleton *ngIf="!createMetricForm.get('versionName')?.value"></p-skeleton>
        </div>

        <!-- Target -->
        <div class="col-3 col-sm-3 fw-bold" translate>
            MODULES.VIEW_METRICS.TARGET
        </div>
        <div class="col-9 col-sm-9">
            {{ createMetricForm.get('target')?.value }}
            <p-skeleton *ngIf="!createMetricForm.get('target')?.value"></p-skeleton>
        </div>

        <!-- Expected-->
        <div class="col-3 col-sm-3 fw-bold" translate>
            MODULES.VIEW_METRICS.EXPECTED
        </div>
        <div class="col-9 col-sm-9">
            {{ createMetricForm.get('expected')?.value }}
            <p-skeleton *ngIf="!createMetricForm.get('expected')?.value"></p-skeleton>
        </div>

        <!--Start date-->
        <div class="col-3 col-sm-3 fw-bold" translate>
            MODULES.VIEW_METRICS.START_DATE
        </div>
        <div class="col-9 col-sm-9">
            {{ createMetricForm.get('startDate')?.value | date : 'yyyy-MM-dd' }}
            <p-skeleton *ngIf="!createMetricForm.get('startDate')?.value"></p-skeleton>
        </div>

        <!--End date-->
        <div class="col-3 col-sm-3 fw-bold" translate>
            MODULES.VIEW_METRICS.END_DATE
        </div>
        <div class="col-9 col-sm-9">
            {{ createMetricForm.get('endDate')?.value | date : 'yyyy-MM-dd' }}
            <p-skeleton *ngIf="!createMetricForm.get('endDate')?.value"></p-skeleton>
        </div>

        <!-- version statement -->
        <div class="col-3 col-sm-3 fw-bold" translate>
            MODULES.CONTRACTS.STATEMENT
        </div>
        <div class="col-9 col-sm-9">
            {{ createMetricForm.get('statement')?.value }}
            <p-skeleton *ngIf="!createMetricForm.get('statement')?.value"></p-skeleton>
        </div>
    </div>

    <div class="row mt-2">
        <div class="col-6 col-sm-6">
            <button pButton type="button" label="{{ 'ABOUT.CLOSE' | translate }}" class="p-button-outlined"
                style="width: 110px" (click)="closeViewMetric()"></button>
        </div>
    </div>
</div>