import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';

const routes = {
  reportType: `${environment.SLMUrl}/reports/reporttype`,
  reportHeaders: `${environment.SLMUrl}/reports`,
  reportData: `${environment.SLMUrl}/reports`,
};

@Injectable({
  providedIn: 'root',
})
export class ComplianceReportService {
  constructor(private httpClient: HttpClient) {}

  getReportType(reportType: string): any {
    return this.httpClient.get(`${routes.reportType}/${reportType}`);
  }

  getReportHeaders(id: number): any {
    return this.httpClient.get(`${routes.reportHeaders}/${id}/header`);
  }

  getReportData(id: number, body: any, params: any = {}): any {
    return this.httpClient.post(`${routes.reportData}/${id}/data`, body, { params });
  }
}
