import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { LayoutService } from '../services/layout.service';
import { CredentialsService } from '@app/core/services/credentials.service';
import { MenuService } from '@app/layout/services/menu.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[app-menuitem]',
  template: `
    <ng-container *hasPermission="item.requiredPermission">
      <div *ngIf="root && item.visible !== false" class="layout-menuitem-root-text" translate>
        {{ item.displayName }}
      </div>

      <a
        *ngIf="(!item.routerLink || item.items) && item.visible !== false"
        [attr.href]="item.url"
        (click)="itemClick($event)"
        class="menu-root"
        [ngClass]="item.disabled ? 'layout-menuitem-disabled' : 'item.class'"
        [attr.target]="item.target"
        tabindex="0"
        pRipple
        [id]="item.id"
        pTooltip="{{ item.label | translate }}"
        [tooltipDisabled]="!layoutService.state.staticMenuDesktopInactive"
      >
        <i class="material-symbols-outlined"> {{ item.icon }} </i>
        <!-- <i [ngClass]="item.icon" class="layout-menuitem-icon"></i> -->
        <span class="layout-menuitem-text" *ngIf="!layoutService.state.staticMenuDesktopInactive" translate>
          {{ item.label }}
          <p class="mb-0">
            <span class="layout-menuitem-tag" *ngIf="item.tags" translate> {{ item.tags }} </span>
          </p>
        </span>
        <i class="pi pi-fw pi-angle-down layout-submenu-toggler" *ngIf="item.items"></i>
      </a>
      <a
        [id]="item.id"
        pTooltip="{{ item.label | translate }}"
        [tooltipDisabled]="!layoutService.state.staticMenuDesktopInactive"
        *ngIf="item.routerLink && !item.items && item.visible !== false"
        (click)="itemClick($event)"
        [ngClass]="item.disabled ? 'layout-menuitem-disabled' : 'item.class'"
        [routerLink]="item.routerLink"
        routerLinkActive="active-route"
        [routerLinkActiveOptions]="
          item.routerLinkActiveOptions || {
            paths: 'exact',
            queryParams: 'ignored',
            matrixParams: 'ignored',
            fragment: 'ignored'
          }
        "
        [fragment]="item.fragment"
        [queryParamsHandling]="item.queryParamsHandling"
        [preserveFragment]="item.preserveFragment"
        [skipLocationChange]="item.skipLocationChange"
        [replaceUrl]="item.replaceUrl"
        [state]="item.state"
        [queryParams]="item.queryParams"
        [attr.target]="item.target"
        tabindex="0"
        pRipple
      >
        <i class="material-symbols-outlined"> {{ item.icon }} </i>
        <!-- <i [ngClass]="item.icon" class="layout-menuitem-icon"></i> -->

        <span class="layout-menuitem-text" *ngIf="!layoutService.state.staticMenuDesktopInactive" translate
          >{{ item.label }}
        </span>
        <span class="layout-menuitem-tag" *ngIf="item.tags" translate>{{ item.tags }}</span>
        <i class="pi pi-fw pi-angle-down layout-submenu-toggler" *ngIf="item.items"></i>
      </a>

      <ul *ngIf="item.items && item.visible !== false" [@children]="submenuAnimation">
        <ng-template ngFor let-child let-i="index" [ngForOf]="item.items">
          <li app-menuitem [item]="child" [index]="i" [parentKey]="key" [class]="child.badgeClass"></li>
        </ng-template>
      </ul>
    </ng-container>
  `,
  animations: [
    trigger('children', [
      state(
        'collapsed',
        style({
          height: '0',
        })
      ),
      state(
        'expanded',
        style({
          height: '*',
        })
      ),
      transition('collapsed <=> expanded', animate('0.3s cubic-bezier(0.4, 0, 0.5, 1)')),
    ]),
  ],
})
export class AppMenuitemComponent implements OnInit, OnDestroy {
  @Input() item: any;

  @Input() index!: number;

  @Input() @HostBinding('class.layout-root-menuitem') root!: boolean;

  @Input() parentKey!: string;

  active = false;

  menuSourceSubscription: Subscription;

  menuResetSubscription: Subscription;

  key = '';

  tooltipOptions = {
    showDelay: 150,
    autoHide: false,
    tooltipEvent: 'hover',
    tooltipPosition: 'left',
  };

  constructor(
    public layoutService: LayoutService,
    private cd: ChangeDetectorRef,
    public router: Router,
    private menuService: MenuService,
    private translate: TranslateService,
    private credentialsService: CredentialsService
  ) {
    this.menuSourceSubscription = this.menuService.menuSource$.subscribe(value => {
      Promise.resolve(null).then(() => {
        if (value.routeEvent) {
          this.active = !!(value.key === this.key || value.key.startsWith(`${this.key}-`));
        } else if (value.key !== this.key && !value.key.startsWith(`${this.key}-`)) {
          this.active = false;
        }
      });
    });

    this.menuResetSubscription = this.menuService.resetSource$.subscribe(() => {
      this.active = false;
    });

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      if (this.item.routerLink) {
        this.updateActiveStateFromRoute();
      }
    });
  }

  @HostBinding('class.active-menuitem')
  get activeClass(): any {
    return this.active && !this.root;
  }

  get submenuAnimation(): any {
    // eslint-disable-next-line no-nested-ternary
    return this.active ? 'expanded' : 'collapsed';
  }

  ngOnInit(): void {
    this.key = this.parentKey ? `${this.parentKey}-${this.index}` : String(this.index);

    if (this.item.routerLink) {
      this.updateActiveStateFromRoute();
    }
  }

  updateActiveStateFromRoute(): void {
    const activeRoute = this.router.isActive(this.item.routerLink[0], {
      paths: 'exact',
      queryParams: 'ignored',
      matrixParams: 'ignored',
      fragment: 'ignored',
    });

    if (activeRoute) {
      this.menuService.onMenuStateChange({ key: this.key, routeEvent: true });
    }
  }

  itemClick(event: Event): void {
    // avoid processing disabled items
    if (this.item.disabled) {
      event.preventDefault();

      return;
    }

    // execute command
    if (this.item.command) {
      this.item.command({ originalEvent: event, item: this.item });
    }

    // toggle active state
    if (this.item.items) {
      this.active = !this.active;
    }

    this.menuService.onMenuStateChange({ key: this.key });
  }

  logout(): void {
    this.credentialsService.setCredentials();
    this.router.navigate(['/auth/login']);
  }

  ngOnDestroy(): void {
    if (this.menuSourceSubscription) {
      this.menuSourceSubscription.unsubscribe();
    }

    if (this.menuResetSubscription) {
      this.menuResetSubscription.unsubscribe();
    }
  }
}
