import { Component } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { MenuItem } from 'primeng/api';

import { MetricConnectorsService } from '@app/slm/services/metric-connectors.service';
import { MetricPermissionService } from '@app/slm/services/metric-permission.service';

@Component({
  selector: 'app-metricconnectorsaction',
  templateUrl: './metricconnectorsaction.component.html',
  styleUrls: ['./metricconnectorsaction.component.scss'],
})
export class MetricConnectorsActionComponent implements ICellRendererAngularComp {
  metricConnectorData: any;

  constructor(
    private metricConnectorsService: MetricConnectorsService,
    private translateService: TranslateService,
    public metricConnectorsPermissionSvc: MetricPermissionService
  ) {}

  agInit(params: ICellRendererParams): void {
    this.metricConnectorData = params.data;
  }

  refresh(params: ICellRendererParams): boolean {
    this.metricConnectorData = params;

    return true;
  }

  getViewMetricConnectorsByID(data?: boolean): void {
    this.metricConnectorsService.toogleViewSidebar(data || false);
    this.metricConnectorsService.getViewData(this.metricConnectorData);
  }

  actionsMenu(): MenuItem[] {
    return [
      {
        label: this.translateService.instant('MODULES.CONTRACTS.ADD_VERSION'),
        icon: 'pi pi-plus-circle',
        disabled: !this.metricConnectorsPermissionSvc.hasAddMetricVersion(),
      },
      {
        label: this.translateService.instant('MODULES.CONTRACTS.EDIT_VERSION'),
        icon: 'pi pi-file-edit',
        disabled: !this.metricConnectorsPermissionSvc.hasEditMetricVersion(),
      },
    ];
  }
}
