import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ErrorComponent } from './authentication/components/error/error.component';
import { NegateAuthenticationGuard } from './core/guards/negate-authentication.guard';
import { ShellService } from './shell/services/shell.service';
import { VisualDesignerComponent } from './visual-designer/visual-designer.component';
import { environment } from '@env/environment';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'welcome',
    pathMatch: 'full',
  },
  ShellService.headerSidebarRoutes([
    {
      path: 'welcome',
      loadChildren: () =>
        environment.name === 'demo'
          ? import('./dashboard/dashboard.module').then(m => m.DashboardModule)
          : import('./welcome/welcome.module').then(m => m.WelcomeModule),
    },
    {
      path: 'slm',
      data: {
        breadcrumb: 'MODULES.SLM.SLM',
      },
      loadChildren: () => import('./slm/slm.module').then(m => m.SLMModule),
    },
    {
      path: 'reports',
      data: {
        breadcrumb: 'MODULES.REPORTS.REPORTS',
      },
      loadChildren: () => import('./report/report.module').then(m => m.ReportModule),
    },
    {
      path: 'data',
      data: {
        breadcrumb: 'MODULES.DATA.DATA',
      },
      loadChildren: () => import('./data/data.module').then(m => m.DataModule),
    },
    {
      path: 'distributions',
      data: {
        breadcrumb: 'BREADCRUMBS.DISTRIBUTIONS',
      },
      loadChildren: () => import('./distributions/distributions.module').then(m => m.DistributionsModule),
    },
    {
      path: 'settings',
      data: {
        breadcrumb: 'BREADCRUMBS.SYSTEM_SETTINGS',
      },
      loadChildren: () => import('./system-settings/system-settings.module').then(m => m.SystemSettingsModule),
    },
    {
      path: 'visual-designer',
      data: {
        breadcrumb: 'BREADCRUMBS.VISUAL_DESIGNER',
      },
      component: VisualDesignerComponent,
    },
  ]),
  {
    path: 'auth',
    canActivate: [NegateAuthenticationGuard],
    loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule),
  },
  {
    path: 'not-found',
    component: ErrorComponent,
  },
  {
    path: 'forbidden',
    component: ErrorComponent,
  },
  { path: '**', redirectTo: 'not-found' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
