<div class="layout-topbar">

  <div class="d-flex mt-2">
    <div #menubutton class="p-link m-auto sidebar-toggle" id="SEL_SIDEBAR_TOGGLE"
      (click)="layoutService.onMenuToggle()">
      <i class="material-symbols-outlined">apps</i>
    </div>

    <div class="text-center px-4">
      <img src="assets/branding/logo.svg" alt="brand-logo-no-text" role="button" height="35" id="SEL_BRAND_LOGO"
        (click)="redirectToHome()" />
    </div>
  </div>
  <!-- <a class="layout-topbar-logo" routerLink="">

        <img src="assets/layout/images/{{layoutService.config.colorScheme === 'light' ? 'logo-small' : 'logo-small'}}.svg"
            alt="Logo" height="40" class="mr-2" />
    </a> -->
  <!-- <button #menubutton class="p-link layout-menu-button layout-topbar-button topbar-icon" (click)="layoutService.onMenuToggle()">
        <i class="pi pi-arrow-circle-left"></i>
    </button> -->

  <button #topbarmenubutton class="p-link layout-topbar-menu-button layout-topbar-button"
    (click)="layoutService.showProfileSidebar()">
    <i class="pi pi-ellipsis-v"></i>
  </button>

  <span class="p-input-icon-right">
    <i class="pi pi-search"></i>
    <input class="p-inputtext-sm" type="text" placeholder="{{ 'HEADER.SEARCH' | translate }}" pInputText disabled />
  </span>

  <div #topbarmenu class="layout-topbar-menu" [ngClass]="{
      'layout-topbar-menu-mobile-active':
        layoutService.state.profileSidebarVisible
    }">
    <!-- theme toggle icon -->
    <!-- <app-theme-toggle></app-theme-toggle> -->

    <!-- language toggle icon -->
    <app-language-toggle class="my-auto mr-3"></app-language-toggle>

    <!-- admin icon -->
    <button class="p-link mx-4" pTooltip="{{ 'HEADER.ADMIN' | translate }}" tooltipPosition="bottom">
      <i class="pi pi-shield icon-size-color"></i>
    </button>

    <!-- legal icon -->
    <button class="p-link" pTooltip="{{ 'HEADER.LEGAL' | translate }}" tooltipPosition="bottom" (click)="goToLegal()">
      <i class="material-symbols-outlined icon-size-color">balance</i>
    </button>

    <!-- about icon -->
    <button class="p-link mx-4" (click)="viewAboutDetails()" pTooltip="{{ 'HEADER.ABOUT' | translate }}"
      tooltipPosition="bottom">
      <i class="pi pi-info-circle icon-size-color"></i>
    </button>

    <!-- system settings icon -->
    <button class="p-link" [routerLink]="'/settings'" pTooltip="{{ 'HEADER.SETTINGS' | translate }}"
      tooltipPosition="bottom">
      <i class="pi pi-cog icon-size-color"></i>
    </button>

    <!-- help center icon-->
    <button class="p-link mx-4" (click)="goToHelpCenter()" pTooltip="{{ 'HEADER.HELP_CENTER' | translate }}"
      tooltipPosition="bottom">
      <i class="pi pi-question-circle icon-size-color"></i>
    </button>

    <!-- favorites menu icon -->
    <app-favorites class="my-auto"></app-favorites>

    <p-divider layout="vertical"></p-divider>

    <!-- user initials -->
    <button class="p-link" (click)="menu.toggle($event)">
      <p-avatar class="uppercase" [label]="
          credentialsService.user?.given_name?.split('', 1) +
          credentialsService.user?.family_name?.split('', 1)
        " shape="circle" size="large">
      </p-avatar>
      <i class="pi ml-2 pi-angle-down"></i>
    </button>

    <p-menu #menu [popup]="true" [model]="logoutMenu" appendTo="body"></p-menu>

  </div>
</div>

<!-- logout confirmation -->
<p-sidebar [(visible)]="isViewEnabled" position="right" styleClass="p-sidebar-md" [dismissible]="false"
  [closeOnEscape]="false">
  <app-confirmation (closeClicked)="isViewEnabled = $event"></app-confirmation>
</p-sidebar>

<!-- About right sidebar -->
<p-sidebar [(visible)]="isAboutEnabled" position="right" styleClass="p-sidebar-md">
  <app-about *ngIf="isAboutEnabled" (closeClicked)="isAboutEnabled = $event"></app-about>
</p-sidebar>