import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { CredentialsService } from '@app/core/services/credentials.service';

@Component({
  selector: 'app-session-expired',
  templateUrl: './session-expired.component.html',
  styleUrls: ['./session-expired.component.scss'],
})
export class SessionExpiredComponent {
  display = true;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private credentialsService: CredentialsService
  ) {}

  takeToLogin(): void {
    this.credentialsService.setCredentials();
    this.router.navigate(['/auth/login'], {
      relativeTo: this.activatedRoute,
      queryParams: {
        redirect: this.router.routerState.snapshot.url,
      },
    });
  }
}
