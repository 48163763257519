import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule, Optional, SkipSelf } from '@angular/core';
import { RouteReuseStrategy, RouterModule } from '@angular/router';

import { AuthenticationGuard } from './guards/authentication.guard';
import { NegateAuthenticationGuard } from './guards/negate-authentication.guard';
import { ApiPrefixInterceptor } from './interceptors/api-prefix.interceptor';
import { CacheInterceptor } from './interceptors/cache.interceptor';
import { ChunkLoadErrorsInterceptor } from './interceptors/chunk-load-errors.interceptor';
import { ErrorHandlerInterceptor } from './interceptors/error-handler.interceptor';
import { LoaderInterceptor } from './interceptors/loader.interceptor';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { RouteReusableStrategy } from './route-reusable-strategy';
import { HttpService } from './services/http.service';

@NgModule({
  imports: [CommonModule, HttpClientModule, RouterModule],
  declarations: [],
  providers: [
    AuthenticationGuard,
    NegateAuthenticationGuard,
    ErrorHandlerInterceptor,
    ApiPrefixInterceptor,
    TokenInterceptor,
    LoaderInterceptor,
    CacheInterceptor,
    {
      provide: HttpClient,
      useClass: HttpService,
    },
    {
      provide: RouteReuseStrategy,
      useClass: RouteReusableStrategy,
    },
    { provide: ErrorHandler, useClass: ChunkLoadErrorsInterceptor },
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    // Import guard
    if (parentModule) {
      throw new Error(`${parentModule} has already been loaded. Import Core module in the AppModule only.`);
    }
  }
}
