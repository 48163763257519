import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class ValidationService {
  constructor(private translate: TranslateService) {}

  static requiredValidator(control: FormControl): any {
    /* a replacement for the default Validators.required that
         validates an empty input with spaces */
    if (!control.value || (control.value?.trim && control.value?.trim() === '')) {
      return { required: true };
    }

    return null;
  }

  static passwordValidator(control: FormControl): any {
    // {6,100}           - Assert password is between 6 and 100 characters
    // (?=.*[0-9])       - Assert a string has at least one number
    if (control.value.match(/^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]{6,100}$/)) {
      return null;
    }

    return { invalidPassword: true };
  }

  static emailValidator(control: FormControl): any {
    // RFC 2822 compliant regex
    if (
      control.value.match(
        /[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?/
      )
    ) {
      return null;
    }

    return { invalidEmailAddress: true };
  }

  static notAllowedSpaceValidator(control: FormControl): any {
    const userInput = control.value;

    if (userInput && userInput.length > 0) {
      if (userInput[0] === ' ') {
        return { invalidSearch: true };
      }
    } else {
      return null;
    }
  }

  getValidatorErrorMessage(validatorName: string, validatorValue?: any): string {
    const config = {
      required: this.translate.instant('VALIDATION.REQUIRED'),
      invalidEmailAddress: this.translate.instant('VALIDATION.INVALID_EMAIL_ADDRESS'),
      invalidPassword: this.translate.instant('VALIDATION.INVALID_PASSWORD'),
      minlength: this.translate.instant('VALIDATION.MINLENGTH', {
        requiredLength: validatorValue.requiredLength,
      }),
      maxlength: this.translate.instant('VALIDATION.MAXLENGTH', {
        requiredLength: validatorValue.requiredLength,
      }),
      invalidSearch: this.translate.instant('VALIDATION.INVALID_SEARCH'),
    };

    return config[validatorName];
  }
}
