import { Directive, ElementRef, Input, TemplateRef, ViewContainerRef } from '@angular/core';

import { CredentialsService } from '@app/core/services/credentials.service';

@Directive({
  selector: '[hasPermission]',
})
export class HasPermissionDirective {
  private permissions = '';

  constructor(
    private element: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private credentialsService: CredentialsService
  ) {}

  @Input()
  set hasPermission(val: any) {
    this.permissions = val;
    this.updateView();
  }

  private updateView(): void {
    if (this.checkPermission()) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  private checkPermission(): boolean {
    let hasPermission = true;

    if (this.permissions && this.credentialsService.isAuthenticated() && this.credentialsService.permissions) {
      hasPermission = !!this.credentialsService.permissions.includes(this.permissions);
    }

    return hasPermission;
  }
}
