import { Component, OnInit, Output, EventEmitter } from '@angular/core';

import { HeaderService } from '../../services/header.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
})
export class AboutComponent implements OnInit {
  @Output() closeClicked = new EventEmitter<boolean>();

  loaderCount: any = Array(7);

  aboutData: any = [];

  constructor(private headerService: HeaderService) {}

  closeAboutSidebar(): void {
    this.aboutData = [];
    this.closeClicked.emit(false);
  }

  ngOnInit(): void {
    this.getAboutData();
  }

  getAboutData(): void {
    this.headerService.getAbout().subscribe((res: any) => {
      if (res) {
        this.aboutData = res;
      }
    });
  }
}
