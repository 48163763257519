import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ComplianceReportComponent } from './components/compliance-report/compliance-report.component';
import { MetricConnectorsComponent } from './components/metric-connectors/metric-connectors.component';
import { MetricsComponent } from './components/metrics/metrics.component';
import { PermissionGuard } from '@app/core/guards/permission.guard';
import { CatalogComponent } from '@app/slm/components/catalog/catalog.component';
import { ContractComponent } from '@app/slm/components/contract/contract.component';
import { OrganizationComponent } from '@app/slm/components/organization/organization.component';

const routes: Routes = [
  {
    path: 'metrics',
    data: {
      breadcrumb: 'MODULES.SLM.METRICS',
      requiredPermission: 'Metric View',
    },
    canActivate: [PermissionGuard],
    component: MetricsComponent,
  },
  {
    path: 'contracts',
    component: ContractComponent,
    data: {
      breadcrumb: 'MODULES.SLM.CONTRACTS',
      requiredPermission: 'Contract View',
    },
    canActivate: [PermissionGuard],
    pathMatch: 'full',
  },
  {
    path: 'metric-connectors',
    data: {
      breadcrumb: 'MODULES.SLM.METRIC_CONNECTOR',
      requiredPermission: 'Metric connector View',
    },
    canActivate: [PermissionGuard],
    component: MetricConnectorsComponent,
  },
  {
    path: 'organizations',
    data: {
      breadcrumb: 'MODULES.SLM.ORGANIZATIONS',
      requiredPermission: 'Organizations View',
    },
    canActivate: [PermissionGuard],
    component: OrganizationComponent,
  },
  {
    path: 'catalog',
    data: {
      breadcrumb: 'MODULES.SLM.CATALOG',
      requiredPermission: 'Metric catalog View',
    },
    canActivate: [PermissionGuard],
    component: CatalogComponent,
  },
  {
    path: 'compliance-report',
    data: {
      breadcrumb: 'MODULES.SLM.COMPILANCE_REPORT',
      requiredPermission: 'Reports View',
    },
    canActivate: [PermissionGuard],
    component: ComplianceReportComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SLMRoutingModule {}
