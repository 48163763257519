import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Subject } from 'rxjs';

import { environment } from '../../../environments/environment';

const routes = {
  metricsTree: `${environment.SLMUrl}/hierarchies/hierarchyOfBusinessServiceForMetric`,
  metricsTable: `${environment.SLMUrl}/metrics/businessServiceId/`,
  metricDetails: `${environment.SLMUrl}/metrics/details/`,
  metricConnectors: `${environment.SLMUrl}/metricconnectors/search`,
  contracts: `${environment.SLMUrl}/contracts/search`,
  metricPeriod: `${environment.SLMUrl}/metricperiods`,
  metricCondition: `${environment.SLMUrl}/metricconditions`,
  metricTargetFormat: `${environment.SLMUrl}/metrictargetdatatypes`,
  metricTargetSubFormat: `${environment.SLMUrl}/metrictargetformats`,
  metricCreate: `${environment.SLMUrl}/metrics`,
  udfRoute: `${environment.SLMUrl}/object-fields`,
  metricHeaders: `${environment.SLMUrl}/reports/Metrics/header`,
  metricData: `${environment.SLMUrl}/reports/Metrics/data`,
};

@Injectable({
  providedIn: 'root',
})
export class MetricsService {
  viewData = new BehaviorSubject<any>('');

  selectedNodeData = new BehaviorSubject<any>('');

  createdMetricData = new Subject<any>();

  editVersionInfo = new Subject<any>();

  addVersionInfo = new Subject<any>();

  selectedVersion = new BehaviorSubject<any>('');

  isSidebarOpen = new BehaviorSubject<boolean>(false);

  isViewOpen$ = this.isSidebarOpen.asObservable();

  isSelectedVersion$ = this.selectedVersion.asObservable();

  constructor(private httpClient: HttpClient) {}

  getViewData(data: any): void {
    this.viewData.next(data);
  }

  getSelectedNodeData(data: any): void {
    this.selectedNodeData.next(data);
  }

  getCreatedMetricData(data: any): void {
    this.createdMetricData.next(data);
  }

  getEditVersionInfo(data: boolean): void {
    this.editVersionInfo.next(data);
  }

  getAddVersionInfo(data: boolean): void {
    this.addVersionInfo.next(data);
  }

  getSelectedVersion(data: any): void {
    this.selectedVersion.next(data);
  }

  toogleSidebar(data: any): void {
    this.isSidebarOpen.next(data);
  }

  getMetricTreeData(): any {
    return this.httpClient.get(routes.metricsTree);
  }

  getMetricsTableData(businessId: string, params: any = {}): any {
    return this.httpClient.get(`${routes.metricsTable + businessId}`, { params });
  }

  getViewMetric(id: number): any {
    return this.httpClient.get(`${routes.metricDetails}${id}`);
  }

  getMetricConnectors(params: any = {}): any {
    return this.httpClient.get(routes.metricConnectors, { params });
  }

  getContracts(params: any = {}): any {
    return this.httpClient.get(routes.contracts, { params });
  }

  getMetricCondition(): any {
    return this.httpClient.get(routes.metricCondition);
  }

  getTargetFormat(): any {
    return this.httpClient.get(routes.metricTargetFormat);
  }

  getMetricSubFormat(): any {
    return this.httpClient.get(routes.metricTargetSubFormat);
  }

  getMetricPeriod(): any {
    return this.httpClient.get(routes.metricPeriod);
  }

  createNewMetric(payload: any): any {
    return this.httpClient.post(routes.metricCreate, payload);
  }

  saveUDFMetric(payload: any): any {
    return this.httpClient.put(routes.metricCreate, payload);
  }

  createNewVersion(id: number, payload: any): any {
    return this.httpClient.post(`${routes.metricCreate}/${id}/versions`, payload);
  }

  publishVersion(id: number, payload: any, updateUDF: boolean): any {
    return this.httpClient.post(`${routes.metricCreate}/${id}/versions/publish?isUdfUpdate=${updateUDF}`, payload);
  }

  publishMetricVersion(payload: any): any {
    return this.httpClient.post(`${routes.metricCreate}/publish`, payload);
  }

  editVersionDetails(id: number, versionId: number, payload: any): any {
    return this.httpClient.put(`${routes.metricCreate}/${id}/versions/${versionId}`, payload);
  }

  revokeVersion(id: number, versionId: number, payload: any): any {
    return this.httpClient.put(`${routes.metricCreate}/${id}/versions/${versionId}/revoke`, payload);
  }

  getUDFInfo(params: any): any {
    return this.httpClient.get(`${routes.udfRoute}/${params.type}`);
  }

  getMetricHeaders(params: any = {}): any {
    return this.httpClient.get(`${routes.metricHeaders}`, { params });
  }

  getMetricData(body: any, params: any = {}): any {
    return this.httpClient.post(`${routes.metricData}`, body, { params });
  }
}
