import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FilterParamsService {
  getMeFilterParams(type: string): any {
    if (
      type === 'String' ||
      type === 'Rich Text' ||
      type === 'Value Set' ||
      type === 'Hierarchy' ||
      type === 'Text' ||
      type === 'Email recipients'
    ) {
      return {
        buttons: ['apply', 'reset'],
        closeOnApply: true,
        maxNumConditions: 2,
        caseSensitive: true,
      };
    }
    if (type === 'Number') {
      return {
        buttons: ['apply', 'reset'],
        closeOnApply: true,
        maxNumConditions: 2,
      };
    }
    if (type === 'Date' || type === 'Date and Time' || type === 'Month' || type === 'Year') {
      return {
        buttons: ['apply', 'reset'],
        closeOnApply: true,
        filterOptions: ['lessThan', 'greaterThan', 'inRange', 'equals', 'notEqual', 'blank', 'notBlank'],
        maxNumConditions: 2,
      };
    }

    return '';
  }
}
