<div class="container-fluid">

  <!-- button section -->
  <div class="row">
    <div class="d-flex bd-highlight">
        <div class="p-2 flex-grow-1 bd-highlight"></div>
        <div class="p-2 bd-highlight">
            <button pButton class="p-button" type="button" [disabled]="!selected.key"
                label="{{ 'MODULES.DATA.HIERARCHIES.VIEW_DETAILS' | translate }}"
                (click)="getOrganizationData()">
            </button>
        </div>
    </div>
  </div>

  <!-- table section -->
  <div class="row">
    <!-- tree structure -->
    <div class="col-12 col-sm-12">
      <p-tree styleClass="tree-height" [value]="metricTreeData" selectionMode="single" [(selection)]="selected"
        (onNodeSelect)="nodeSelect()" emptyMessage="{{ 'AUTH.DEFAULT_EMPTY_MSG' | translate }}">
        <ng-template let-node pTemplate="default">
          <div class="d-flex w-100">
            <i [ngClass]="
                node.expanded
                  ? 'pi pi-folder-open mr-2 my-auto tree-folder-icon'
                  : 'pi pi-folder mr-2 my-auto tree-folder-icon'
              "></i>
            <div class="d-flex">
              <h6 class="my-auto">{{ node.name }}</h6>
              <p *ngIf="node.countOfChildren" class="ms-2 my-auto">
                ({{ node.countOfChildren }}
                {{
                  node.countOfChildren > 1
                    ? node.childLevelNamePlural
                    : node.childLevelName
                }})
              </p>
            </div>
          </div>
        </ng-template>
      </p-tree>
    </div>
  </div>
</div>

<p-sidebar [(visible)]="isViewOrganization" position="right" styleClass="p-sidebar-lg right-sidebar-view"
  *ngIf="isViewOrganization">
  <app-view-organization (closeClicked)="isViewOrganization = $event"></app-view-organization>
</p-sidebar>
