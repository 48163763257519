<div class="right-sidebar-header">
    <h5 class="px-4 fw-bold d-inline" translate>MODULES.CREATE_METRIC.ADD_TITLE</h5>
    <p-divider styleClass="mb-0"></p-divider>
</div>

<form [formGroup]="createMetricForm">
    <div class="px-4">
        <div class="row mt-2">
            <!-- metric id -->
            <div class="col-4">
                <label class="fw-bold mb-2" for="id">{{
                    'MODULES.VIEW_METRICS.METRIC_ID' | translate
                    }}</label>
                <span class="w-100  mt-2 mb-1">
                    <input class="w-100 height-40 p-inputtext-sm"
                        placeholder="{{ 'MODULES.VIEW_METRICS.METRIC_ID' | translate}}" type="text" pInputText
                        formControlName="id" />
                </span>
                <div *ngIf="createMetricForm.get('id')?.touched">
                    <small *ngIf="createMetricForm.get('id')?.errors?.['required']" class="text-danger">
                        {{ 'VALIDATION.REQUIRED' | translate }}
                    </small>

                    <small *ngIf="createMetricForm.get('id')?.errors?.['maxlength']" class="text-danger">
                        {{ 'VALIDATION.MAXLENGTH' | translate : '{requiredLength: 20}' }}
                    </small>
                </div>
            </div>

            <!-- metric name -->
            <div class="col-4 ">
                <label class="fw-bold mb-2" for="name">{{
                    'MODULES.CREATE_METRIC.METRIC_NAME' | translate
                    }}</label>
                <span class="w-100  mt-2 mb-1">
                    <input class="w-100 height-40 p-inputtext-sm" type="text"
                        placeholder="{{ 'MODULES.CREATE_METRIC.METRIC_NAME' | translate}}" pInputText
                        formControlName="name" />
                </span>
                <div *ngIf="createMetricForm.get('name')?.touched">
                    <small *ngIf="createMetricForm.get('name')?.errors?.['required']" class="text-danger">
                        {{ 'VALIDATION.REQUIRED' | translate }}
                    </small>
                    <small *ngIf="createMetricForm.get('name')?.errors?.['maxlength']" class="text-danger">
                        {{ 'VALIDATION.MAXLENGTH' | translate : '{requiredLength: 30}' }}
                    </small>
                </div>
            </div>

            <!-- metric connector -->
            <div class="col-4 ">
                <label class="fw-bold mb-2" for="metricConnector">{{
                    'MODULES.METRICS.METRIC_CONNECTOR' | translate
                    }}</label>
                <span class="w-100  mt-1 ">
                    <p-autoComplete styleClass="w-100" inputStyleClass="w-100 height-40"
                        placeholder="{{'MODULES.METRICS.METRIC_CONNECTOR' | translate}}" [suggestions]="connectorList"
                        field="name" (completeMethod)="metricConnectorData($event)" [showEmptyMessage]="true"
                        emptyMessage="{{ 'AUTH.DEFAULT_EMPTY_MSG' | translate }}" [minLength]="3"
                        [forceSelection]="true" [showClear]="true" formControlName="metricConnector"
                        (onSelect)="onSelectConnector()" (onClear)="onResetConnector()">
                    </p-autoComplete>
                </span>
                <small class="font-12">
                    <b>{{ 'VALIDATION.AUTOCOMPLETE_MINLENGTH' | translate }}</b>
                </small>
                <p class="mt-2 mb-0" *ngIf="isSelectedConnector">
                    {{ 'MODULES.VIEW_METRICS.PARAMETER' | translate }}:
                    {{
                    createMetricForm.get('metricConnector')?.value['paramsAsString']
                    ? createMetricForm.get('metricConnector')?.value['paramsAsString']
                    : 'N/A'
                    }}
                </p>
            </div>

            <!-- contract name -->
            <div class="col-4 ">
                <label class="fw-bold mb-2" for="contract">{{
                    'MODULES.CREATE_METRIC.CONTRACT_NAME' | translate
                    }}</label>
                <span class="w-100  mt-1">
                    <p-autoComplete styleClass="w-100" inputStyleClass="w-100 height-40"
                        placeholder="{{ 'MODULES.CREATE_METRIC.CONTRACT_NAME' | translate}}"
                        [suggestions]="contractList" field="contractName" (completeMethod)="contractData($event)"
                        [showEmptyMessage]="true" emptyMessage="{{ 'AUTH.DEFAULT_EMPTY_MSG' | translate }}"
                        [minLength]="3" [forceSelection]="true" [showClear]="true" formControlName="contract"
                        value="contractId" (onSelect)="onSelectContract($event)" (onClear)="onResetContractField()">
                    </p-autoComplete>
                </span>
                <small class="font-12">
                    <b>{{ 'VALIDATION.AUTOCOMPLETE_MINLENGTH' | translate }}</b>
                </small>
                <div *ngIf="createMetricForm.get('contract')?.touched">
                    <small *ngIf="createMetricForm.get('contract')?.errors?.['required']" class="text-danger">
                        {{ 'VALIDATION.REQUIRED' | translate }}
                    </small>
                </div>
            </div>

            <!-- customer -->
            <div class="col-4 ">
                <label class="fw-bold mb-2" for="customer">{{
                    'MODULES.METRICS.CUSTOMER' | translate
                    }}</label>
                <span class="w-100  my-1">
                    <input class="w-100 height-40 p-inputtext-sm" type="text"
                        placeholder="{{'MODULES.METRICS.CUSTOMER' | translate}}" pInputText formControlName="customer"
                        [attr.disabled]="true" />
                </span>
                <div *ngIf="createMetricForm.get('customer')?.touched">
                    <small *ngIf="createMetricForm.get('customer')?.errors?.['required']" class="text-danger">
                        {{ 'VALIDATION.REQUIRED' | translate }}
                    </small>
                </div>
            </div>

            <!-- provider -->
            <div class="col-4 ">
                <label class="fw-bold mb-2" for="provider">{{
                    'MODULES.METRICS.PROVIDER' | translate
                    }}</label>
                <span class="w-100  my-1">
                    <input class="w-100 height-40 p-inputtext-sm" type="text"
                        placeholder="{{ 'MODULES.METRICS.PROVIDER' | translate}}" pInputText formControlName="provider"
                        [attr.disabled]="true" />
                </span>
                <div *ngIf="createMetricForm.get('provider')?.touched">
                    <small *ngIf="createMetricForm.get('provider')?.errors?.['required']" class="text-danger">
                        {{ 'VALIDATION.REQUIRED' | translate }}
                    </small>
                </div>
            </div>

            <!-- business service -->
            <div class="col-4 ">
                <label class="fw-bold mb-2" for="businessService">{{
                    'MODULES.CREATE_METRIC.BUSINESS_SERVICE' | translate
                    }}</label>
                <span class="w-100 my-1">

                    <p-treeSelect [formControlName]="'businessService'" containerStyleClass="w-100" optionLabel="name"
                        optionValue="id" [showClear]="true" emptyMessage="{{ 'AUTH.DEFAULT_EMPTY_MSG' | translate }}"
                        [options]="businessServiceList" [filter]="true" filterBy="name" placeholder="{{
                            'MODULES.CREATE_METRIC.BUSINESS_SERVICE' | translate
                            }}">
                        <ng-template let-node pTemplate="default">
                            <div class="d-flex w-100">
                                <i [ngClass]="
                                node.expanded
                                  ? 'pi pi-folder-open mr-2 my-auto tree-folder-icon'
                                  : 'pi pi-folder mr-2 my-auto tree-folder-icon'
                              "></i>
                                <div class="my-auto">
                                    <h6>{{ node.name }}</h6>
                                </div>
                            </div>
                        </ng-template>
                        <!-- to display selected value -->
                        <ng-template let-node pTemplate="value">
                            {{ node?.name ? node?.name : 'MODULES.CREATE_METRIC.BUSINESS_SERVICE' | translate }}
                        </ng-template>
                    </p-treeSelect>
                </span>
                <div
                    *ngIf="createMetricForm.get('businessService')?.touched || !createMetricForm.get('businessService')?.pristine">
                    <small *ngIf="createMetricForm.get('businessService')?.errors?.['required']" class="text-danger">
                        {{ 'VALIDATION.REQUIRED' | translate }}
                    </small>
                </div>
            </div>

            <!-- target condition -->
            <div class="col-4 ">
                <label class="fw-bold mb-2" for="target">{{
                    'MODULES.CREATE_METRIC.CONDITION' | translate
                    }}</label>
                <span class="w-100  my-1">
                    <p-dropdown styleClass="w-100  height-40" [options]="conditionList" formControlName="condition"
                        optionLabel="name" optionValue="id"
                        placeholder="{{ 'MODULES.VIEW_METRICS.CONDITION' | translate }}" [showClear]="true"
                        [autoDisplayFirst]="false" emptyMessage="{{ 'AUTH.DEFAULT_EMPTY_MSG' | translate }}">
                    </p-dropdown>
                </span>
                <div *ngIf="createMetricForm.get('condition')?.touched">
                    <small *ngIf="createMetricForm.get('condition')?.errors?.['required']" class="text-danger">
                        {{ 'VALIDATION.REQUIRED' | translate }}
                    </small>
                </div>
            </div>

            <!-- format -->
            <div class="col-4 ">
                <label class="fw-bold mb-2" for="target">{{
                    'MODULES.CREATE_METRIC.FORMAT' | translate
                    }}</label>
                <span class="w-100  my-1">
                    <p-dropdown styleClass="w-100 height-40" [options]="formatList" formControlName="targetFormat"
                        optionLabel="name" optionValue="id"
                        placeholder="{{ 'MODULES.CREATE_METRIC.FORMAT' | translate }}" [editable]="true"
                        emptyMessage="{{ 'AUTH.DEFAULT_EMPTY_MSG' | translate }}" (onChange)="updateSubFormat()"
                        optionDisabled="inactive">
                    </p-dropdown>

                </span>
            </div>

            <!-- sub format -->
            <div class="col-4 ">
                <label class="fw-bold mb-2" for="target">{{
                    'MODULES.CREATE_METRIC.SUB_FORMAT' | translate
                    }}</label>
                <span class="w-100  my-1">
                    <p-dropdown styleClass="w-100 height-40" [options]="subFormatList" formControlName="subTargetFormat"
                        optionLabel="name" optionValue="id" [disabled]="!createMetricForm.get('targetFormat')?.value"
                        placeholder="{{ 'MODULES.VIEW_METRICS.SUB_FORMAT' | translate }}"
                        emptyMessage="{{ 'AUTH.DEFAULT_EMPTY_MSG' | translate }}">
                    </p-dropdown>

                </span>
                <div *ngIf="createMetricForm.get('subTargetFormat')?.touched">
                    <small *ngIf="createMetricForm.get('subTargetFormat')?.errors?.['required']" class="text-danger">
                        {{ 'VALIDATION.REQUIRED' | translate }}
                    </small>
                </div>
            </div>

            <!-- period -->
            <div class="col-4 ">
                <label class="fw-bold mb-2" for="period">{{
                    'MODULES.CREATE_METRIC.PERIOD' | translate
                    }}</label>
                <span class="w-100  my-1">
                    <p-dropdown styleClass="w-100 height-40" [options]="periodList" [editable]="true"
                        formControlName="period" optionLabel="name" optionValue="id"
                        placeholder="{{ 'MODULES.VIEW_METRICS.PERIOD' | translate }}"
                        emptyMessage="{{ 'AUTH.DEFAULT_EMPTY_MSG' | translate }}">
                    </p-dropdown>
                </span>
                <div *ngIf="createMetricForm.get('period')?.touched">
                    <small *ngIf="createMetricForm.get('period')?.errors?.['required']" class="text-danger">
                        {{ 'VALIDATION.REQUIRED' | translate }}
                    </small>
                </div>
            </div>

            <!-- description -->
            <div class="col-4 ">
                <label class="fw-bold mb-2" for="description">{{
                    'MODULES.VIEW_METRICS.DESCRIPTION' | translate
                    }}</label>
                <span class="w-100  my-1">
                    <textarea class="w-100 height-40" placeholder="{{ 'MODULES.VIEW_METRICS.DESCRIPTION' | translate}}"
                        pInputTextarea formControlName="description"></textarea>

                </span>
                <div *ngIf="createMetricForm.get('description')?.touched">
                    <small *ngIf="createMetricForm.get('description')?.errors?.['maxlength']" class="text-danger">
                        {{ 'VALIDATION.MAXLENGTH' | translate : '{requiredLength: 1000}' }}
                    </small>
                </div>
            </div>

            <!-- statement -->
            <div class="col-4">
                <label class="fw-bold mb-2" for="statement">{{
                    'MODULES.CREATE_METRIC.STATEMENT' | translate
                    }}</label>
                <span class="w-100  my-1">
                    <textarea class="w-100 height-40" placeholder="{{ 'MODULES.CREATE_METRIC.STATEMENT' | translate}}"
                        pInputTextarea formControlName="statement"></textarea>
                </span>
                <div *ngIf="createMetricForm.get('statement')?.touched">
                    <small *ngIf="createMetricForm.get('statement')?.errors?.['required']" class="text-danger">
                        {{ 'VALIDATION.REQUIRED' | translate }}
                    </small>

                    <small *ngIf="createMetricForm.get('statement')?.errors?.['maxlength']" class="text-danger">
                        {{ 'VALIDATION.MAXLENGTH' | translate : '{requiredLength: 250}' }}
                    </small>
                </div>
            </div>

        </div>
    </div>

    <!--UDF Metric-->
    <h5 translate class="mt-4 px-4" *ngIf="UDFList && UDFList.length > 0">MODULES.CREATE_METRIC.UDF_METRIC</h5>
    <p-divider styleClass="mb-0"></p-divider>

    <div class="px-4" *ngIf="UDFList && UDFList.length > 0">
        <div class="row">
            <app-udf [udfInfo]="UDFList" (formDataChanged)="onFormDataChanged($event)"></app-udf>
        </div>
    </div>

    <!-- version details -->
    <div class="mt-4 ">

        <h5 translate class="header-color py-3 px-4">MODULES.CREATE_METRIC.VERSION_DETAILS</h5>
        <p-divider styleClass="my-0"></p-divider>
    </div>

    <div class=" px-4">
        <div class="row">
            <!-- version name -->
            <div class="col-4">
                <label class="fw-bold mb-2" for="versionName">{{
                    'MODULES.CREATE_METRIC.VERSION_NAME' | translate
                    }}</label>
                <span class="w-100  my-1">
                    <input class="w-100 height-40 p-inputtext-sm"
                        placeholder="{{  'MODULES.CREATE_METRIC.VERSION_NAME' | translate}}" type="text" pInputText
                        formControlName="versionName" />

                </span>
                <div *ngIf="createMetricForm.get('versionName')?.touched">
                    <small *ngIf="createMetricForm.get('versionName')?.errors?.['required']" class="text-danger">
                        {{ 'VALIDATION.REQUIRED' | translate }}
                    </small>

                    <small *ngIf="createMetricForm.get('versionName')?.errors?.['maxlength']" class="text-danger">
                        {{ 'VALIDATION.MAXLENGTH' | translate : '{requiredLength: 20}' }}
                    </small>
                </div>
            </div>

            <!-- target -->
            <div class="col-4">
                <label class="fw-bold mb-2" for="target">{{
                    'MODULES.VIEW_METRICS.TARGET' | translate
                    }}</label>
                <span class="w-100  my-1">
                    <p-inputNumber mode="decimal" placeholder="{{'MODULES.VIEW_METRICS.TARGET' | translate}}"
                        [minFractionDigits]="1" formControlName="target" (onBlur)="getTagetValue()"></p-inputNumber>

                </span>
                <div *ngIf="createMetricForm.get('target')?.touched">
                    <small *ngIf="createMetricForm.get('target')?.errors?.['required']" class="text-danger">
                        {{ 'VALIDATION.REQUIRED' | translate }}
                    </small>
                </div>
            </div>

            <!-- expected -->
            <div class="col-4">
                <label class="fw-bold  mb-2" for="expected">{{
                    'MODULES.VIEW_METRICS.EXPECTED' | translate
                    }}</label>
                <span class="w-100  my-1">
                    <p-inputNumber mode="decimal" placeholder="{{ 'MODULES.VIEW_METRICS.EXPECTED' | translate}}"
                        [minFractionDigits]="1" formControlName="expected"></p-inputNumber>
                </span>
                <div *ngIf="createMetricForm.get('expected')?.touched">
                    <small *ngIf="createMetricForm.get('expected')?.errors?.['required']" class="text-danger">
                        {{ 'VALIDATION.REQUIRED' | translate }}
                    </small>
                </div>
            </div>

            <!-- start date -->
            <div class="col-4">
                <label class="fw-bold mb-2" for="startDate">{{
                    'MODULES.VIEW_METRICS.START_DATE' | translate
                    }}</label>
                <span class="w-100  my-1">
                    <p-calendar styleClass="w-100 height-40"
                        placeholder="{{ 'MODULES.VIEW_METRICS.START_DATE' | translate}}" formControlName="startDate"
                        [showIcon]="true" dateFormat="dd-mm-yy" [minDate]="minStartDate"
                        (onSelect)="onSelectStartDate($event)" [readonlyInput]="true" appendTo="body">
                    </p-calendar>

                </span>
                <div *ngIf="createMetricForm.get('startDate')?.touched">
                    <small *ngIf="createMetricForm.get('startDate')?.errors?.['required']" class="text-danger">
                        {{ 'VALIDATION.REQUIRED' | translate }}
                    </small>
                </div>
            </div>

            <!-- end date -->
            <div class="col-4">
                <label class="fw-bold mb-2" for="endDate">{{
                    'MODULES.VIEW_METRICS.END_DATE' | translate
                    }}</label>
                <span class="w-100  my-1">
                    <p-calendar styleClass="w-100 height-40"
                        placeholder="{{ 'MODULES.VIEW_METRICS.END_DATE' | translate}}" formControlName="endDate"
                        [showIcon]="true" [minDate]="minEndDate" [maxDate]="maxEndDate" dateFormat="dd-mm-yy"
                        [readonlyInput]="true" [defaultDate]="minEndDate" appendTo="body">
                    </p-calendar>
                </span>
                <div *ngIf="createMetricForm.get('endDate')?.touched">
                    <small *ngIf="createMetricForm.get('endDate')?.errors?.['required']" class="text-danger">
                        {{ 'VALIDATION.REQUIRED' | translate }}
                    </small>
                </div>
            </div>

            <!-- version statement -->
            <div class="col-4">
                <label class="fw-bold mb-2" for="metricVersionStatement">{{
                    'MODULES.CONTRACTS.STATEMENT' | translate
                    }}</label>
                <span class="w-100  my-1">
                    <textarea class="w-100 height-40" placeholder="{{ 'MODULES.CONTRACTS.STATEMENT' | translate}}"
                        pInputTextarea formControlName="metricVersionStatement"></textarea>
                </span>
                <div *ngIf="createMetricForm.get('metricVersionStatement')?.touched">
                    <small *ngIf="createMetricForm.get('metricVersionStatement')?.errors?.['maxlength']"
                        class="text-danger">
                        {{ 'VALIDATION.MAXLENGTH' | translate : '{requiredLength: 250}' }}
                    </small>
                </div>
            </div>
        </div>
    </div>

    <!--UDF Version-->

    <h5 translate class="mt-4 px-4" *ngIf="UDFVersionList && UDFVersionList.length > 0">
        MODULES.VIEW_METRICS.UDF_VERSION
    </h5>
    <p-divider styleClass="mb-0"></p-divider>

    <div class="px-4" *ngIf="UDFVersionList && UDFVersionList.length > 0">
        <div class="row">
            <app-udf [udfInfo]="UDFVersionList" (formDataChanged)="onFormDataChanged($event)"></app-udf>
        </div>
    </div>
    <div class="px-4">

        <!-- api error msg block -->
        <ng-container *ngIf="errorMsgList?.length">
            <div class="error-block">
                <li *ngFor="let msg of errorMsgList">{{ msg }}</li>
            </div>
        </ng-container>

        <!-- btn section -->
        <div class="row mt-2">
            <div class="col-4 col-sm-4">
                <button pButton type="button" label="{{ 'MODULES.DISTRIBUTIONS.CANCEL' | translate }}"
                    class="p-button-outlined" style="width: 110px" (click)="resetForm()"></button>
            </div>
            <div class="col-4 col-sm-4 text-center">
                <button pButton type="submit" label="{{ 'MODULES.DISTRIBUTIONS.SAVE' | translate }}"
                    [disabled]="!metricPermission.hasEditMetricVersion()" class="p-button" style="width: 110px"
                    (click)="saveNewMetric()"></button>
            </div>
            <div class="col-4 col-sm-4 text-right">
                <button pButton type="submit" label="{{ 'MODULES.CREATE_METRIC.PUBLISH' | translate }}"
                    [disabled]="!metricPermission.hasPublishMetricVersion()" class="p-button" style="width: 110px"
                    (click)="cofirmPublish($event)"></button>
            </div>
        </div>
    </div>
</form>

<p-confirmPopup></p-confirmPopup>