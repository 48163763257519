import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-read-more',
  templateUrl: './read-more.component.html',
  styleUrls: ['./read-more.component.scss'],
})
export class ReadMoreComponent implements OnInit {
  @Input() content!: any;

  @Input() limit!: number;

  @Input() width!: number;

  @Input() completeWords!: boolean;

  @Input() events!: boolean;

  @Output() downloadTemplateEnv = new EventEmitter<any>();

  isContentToggled!: boolean;

  nonEditedContent!: string;

  toggleContent(): void {
    this.isContentToggled = !this.isContentToggled;

    // if content type is obj then display with comma seperated value
    if (typeof this.nonEditedContent === 'object' && this.isContentToggled) {
      this.content = Object.values(this.nonEditedContent).join(', ');
    } else {
      this.content = this.isContentToggled ? this.nonEditedContent : this.formatContent(this.content);
    }
  }

  formatContent(content: any): void {
    let contentDes: any;

    if (typeof content === 'object') {
      const commaSeperateItems: any = Object.values(content).join(', ');

      if (commaSeperateItems.length > this.limit) {
        contentDes = `${commaSeperateItems.substr(0, this.limit)}...`;
      } else if (!Object.keys(content).length) {
        contentDes = '';
      } else {
        contentDes = `${commaSeperateItems.substr(0, this.limit)}`;
      }
    } else if (content.length > this.limit) {
      contentDes = `${content.substr(0, this.limit)}...`;
    } else {
      contentDes = `${content.substr(0, this.limit)}`;
    }

    return contentDes;
  }

  ngOnInit(): void {
    this.nonEditedContent = this.content;
    this.content = this.formatContent(this.content);
  }
}
