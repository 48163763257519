import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CatalogPermissionService {
  hasAddCatalog(): boolean {
    const permissions: any = sessionStorage.getItem('permissions');

    if (permissions?.includes('Metric catalog Add')) {
      return true;
    }

    return false;
  }
}
