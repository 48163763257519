import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PaginationAggridFlexService {
  setLbLastRowOnPage(grid: any, gridElement: any, noRecords?: boolean): void {
    if (!grid) {
      return;
    }

    const pagingPanel = gridElement.nativeElement.getElementsByClassName('ag-paging-panel');

    const pageBlockId = pagingPanel[0].id;
    const lbFirstRowOnPageEl: any = document.querySelector(`#${pageBlockId} [ref=lbFirstRowOnPage]`);
    const lbLastRowOnPageEl: any = document.querySelector(`#${pageBlockId} [ref='lbLastRowOnPage']`);
    const lbRecordCount: any = document.querySelector(`#${pageBlockId} [ref=lbRecordCount]`);
    const lbTotal: any = document.querySelector(`#${pageBlockId} [ref='lbTotal']`);
    const isLastPage = grid.paginationGetTotalPages() === grid.paginationGetCurrentPage() + 1;
    const endRow = isLastPage
      ? grid.paginationGetRowCount()
      : (grid.paginationGetCurrentPage() + 1) * grid.paginationGetPageSize();

    if (lbLastRowOnPageEl) {
      lbLastRowOnPageEl.innerHTML = endRow.toString();
    }

    // prevent pagination updates on sorting when no records are available
    if (noRecords) {
      lbFirstRowOnPageEl.innerHTML = '0';
      lbRecordCount.innerHTML = '0';
      lbTotal.innerHTML = '0';
    }
  }
}
