import { Component } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { MenuItem } from 'primeng/api';

import { CatalogService } from '@app/slm/services/catalog.service';
import { MetricPermissionService } from '@app/slm/services/metric-permission.service';

@Component({
  selector: 'app-catalog-action',
  templateUrl: './catalog-action.component.html',
  styleUrls: ['./catalog-action.component.scss'],
})
export class CatalogActionComponent implements ICellRendererAngularComp {
  viewCatalogData: any;

  constructor(
    private catalogService: CatalogService,
    private translateService: TranslateService,
    public metricPermissionService: MetricPermissionService
  ) {}

  agInit(params: ICellRendererParams): void {
    this.viewCatalogData = params.data;
  }

  refresh(params: ICellRendererParams): boolean {
    this.viewCatalogData = params;

    return true;
  }

  getViewMetricCatalogByID(data?: boolean): void {
    this.catalogService.toogleViewSidebar(data || false);
    this.catalogService.getViewData(this.viewCatalogData);
  }

  actionsMenu(): MenuItem[] {
    return [
      {
        label: this.translateService.instant('MODULES.CONTRACTS.ADD_VERSION'),
        icon: 'pi pi-plus-circle',
        disabled: !this.metricPermissionService.hasAddMetricVersion(),
      },
      {
        label: this.translateService.instant('MODULES.CONTRACTS.EDIT_VERSION'),
        icon: 'pi pi-file-edit',
        disabled: !this.metricPermissionService.hasEditMetricVersion(),
      },
    ];
  }
}
