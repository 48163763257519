<div class="right-sidebar-header">
  <h5 class="px-4 fw-bold d-inline" translate>DIALOG.CONFIRMATION</h5>
  <p-divider styleClass="mb-0"></p-divider>
</div>

<div class="px-4">
  <div class="row mt-2">
    <div class="col-12 col-sm-12">
      <p translate>DIALOG.MESSAGE</p>
    </div>
    <div class="col-12 col-sm-12">
      <button class="p-button w-25 mr-3" pButton label="{{ 'DIALOG.YES' | translate }}"
        (click)="triggerLogout()"></button>
      <button class="p-button-outlined w-25" pButton type="button" label="{{ 'DIALOG.NO' | translate }}"
        (click)="closeLogout()"></button>
    </div>
  </div>
</div>