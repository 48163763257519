import { Component } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { MenuItem } from 'primeng/api';

import { ContractPermissionService } from '@app/slm/services/contract-permission.service';
import { ContractService } from '@app/slm/services/contract.service';

@Component({
  selector: 'app-action',
  templateUrl: './action.component.html',
  styleUrls: ['./action.component.scss'],
})
export class ActionComponent implements ICellRendererAngularComp {
  contractData: any;

  constructor(
    private contractPermission: ContractPermissionService,
    private contractService: ContractService,
    private translateService: TranslateService
  ) {}

  agInit(params: ICellRendererParams): void {
    this.contractData = params.data;
  }

  refresh(params: ICellRendererParams): boolean {
    this.contractData = params;

    return true;
  }

  getViewContractByID(data?: boolean): void {
    this.contractService.toogleViewSidebar(data || false);
    this.contractService.getViewData(this.contractData);
  }

  actionsMenu(): MenuItem[] {
    return [
      {
        label: this.translateService.instant('MODULES.CONTRACTS.ADD_VERSION'),
        icon: 'pi pi-plus-circle',
        command: () => {
          this.contractService.toogleAddSidebar(true);
          this.contractService.getViewData(this.contractData?.id);
        },
        disabled: !this.contractPermission.hasAddContractVersion(),
      },
      {
        label: this.translateService.instant('MODULES.CONTRACTS.EDIT_VERSION'),
        icon: 'pi pi-file-edit',
        command: () => {
          this.contractService.toogleSidebar(true);
          this.contractService.getViewData(this.contractData?.id);
        },
        disabled: !this.contractPermission.hasEditContractVersion(),
      },
    ];
  }
}
