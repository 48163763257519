import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';

import { Subscription } from 'rxjs';

import { OrganizationService } from '@app/slm/services/organization.service';

@Component({
  selector: 'app-view-organization',
  templateUrl: './view-organization.component.html',
  styleUrls: ['./view-organization.component.scss'],
})
export class ViewOrganizationComponent implements OnInit, OnDestroy {
  @Output() closeClicked = new EventEmitter<boolean>();

  hierarchyData: any;

  customFields: any;

  selectedNodeData: any = {};

  rootNodeData: any = {};

  hierarchySubscription!: Subscription;

  constructor(private metricService: OrganizationService) {}

  ngOnInit(): void {
    this.hierarchySubscription = this.metricService.hierarchyData.subscribe((res: any) => {
      if (res) {
        this.selectedNodeData = res.nodeInfo;
        this.rootNodeData = res.managementDetails;
        this.getHierarchyNodeInfo();
      }
    });
  }

  getHierarchyNodeInfo(): void {
    const hierarchyId = this.rootNodeData.id;
    const nodeId = this.selectedNodeData.businessId;

    this.metricService.getHierarchyNodeData(hierarchyId, nodeId).subscribe((res: any) => {
      if (res) {
        this.hierarchyData = res;
        this.customFields = res.objectFieldData ? res.objectFieldData : [];

        Object.keys(this.hierarchyData).forEach(key => {
          this.hierarchyData[key] = this.hierarchyData[key] ? this.hierarchyData[key] : 'N/A';
        });
      }
    });
  }

  closeViewDetails(): void {
    this.hierarchyData = {};
    this.customFields = [];
    this.selectedNodeData = {};
    this.rootNodeData = {};
    this.closeClicked.emit(false);
  }

  ngOnDestroy(): void {
    this.hierarchySubscription.unsubscribe();
  }
}
