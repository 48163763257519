import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';

import { CatalogService } from '@app/slm/services/catalog.service';

@Component({
  selector: 'app-view-catalog',
  templateUrl: './view-catalog.component.html',
  styleUrls: ['./view-catalog.component.scss'],
})
export class ViewCatalogComponent implements OnInit, OnDestroy {
  @Output() closeClicked = new EventEmitter<boolean>();

  @ViewChild('op', { static: false }) overlayPanel: any;

  @ViewChild('paginationReset') paginationReset!: Table;

  catalogSubscription!: Subscription;

  viewData: any = [];

  metricConnector: any = [];

  viewVersionList: any = [];

  catalogData: any = [];

  selectedVersion: any;

  filteredVersion: any = [];

  createMetricForm!: FormGroup;

  statusList: any = [
    { label: 'Draft', class: 'warning-chip' },
    { label: 'Published', class: 'success-chip' },
    { label: 'Revoked', class: 'danger-chip' },
  ];

  selectedStatusFilter: any = { Draft: false, Published: false, Revoked: false };

  constructor(private catalogService: CatalogService, private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.createMetricForm = this.formBuilder.group({
      versionName: [''],
      status: [''],
      target: [''],
      expected: [''],
      startDate: [''],
      endDate: [''],
      statement: [''],
      statusFilter: new FormArray([]),
    });

    this.addCheckboxes();

    this.catalogSubscription = this.catalogService.viewData.subscribe((res: any) => {
      if (res) {
        this.viewVersionList = [];
        this.catalogData = res;
        this.getViewCatalog(res);
      }
    });
  }

  getViewCatalog(catalogData: any): void {
    this.viewData = [];
    this.catalogService.getViewCatalog(catalogData.id).subscribe((res: any) => {
      if (res) {
        this.viewData = res;
        this.viewVersionList = this.viewData.metricCatalogVersions;

        this.patchValue(this.viewVersionList[0]);

        [this.selectedVersion] = this.viewVersionList;

        this.filteredVersion = this.viewVersionList;

        Object.keys(this.viewData).forEach(key => {
          this.viewData[key] = this.viewData[key] ? this.viewData[key] : 'N/A';
        });
      }
    });
  }

  getMetricConnectorById(id?: any): void {
    this.catalogService.getMetricCatalog(id).subscribe((res: any) => {
      if (res) {
        const resObj = {
          defaultStatement: res.defaultStatement,
          name: res.name,
        };

        Object.keys(resObj).forEach(key => {
          resObj[key] = resObj[key] ? resObj[key] : 'N/A';
        });

        this.metricConnector = resObj;
      }
    });
  }

  patchValue(data: any): void {
    this.createMetricForm.patchValue({
      versionName: data.versionName ? data.versionName : 'N/A',
      status: data.status ? data.status : 'N/A',
      target:
        this.viewData.conditionName && data.formattedMetricTarget && data.formattedMetricTarget !== undefined
          ? `${this.viewData.conditionName} ${data.formattedMetricTarget}`
          : 'N/A',
      expected:
        this.viewData.conditionName && data.formattedExpected && data.formattedExpected !== undefined
          ? `${this.viewData.conditionName} ${data.formattedExpected}`
          : 'N/A',
      startDate: data.startDate ? new Date(data.startDate) : 'N/A',
      endDate: data.endDate ? new Date(data.endDate) : 'N/A',
      statement: data.statement ? data.statement : 'N/A',
    });
  }

  applyStatusFilter(event: any, field: string): void {
    Object.keys(this.selectedStatusFilter).forEach(key => {
      if (key === field) {
        this.selectedStatusFilter[key] = event.checked;
      }
    });

    // filter the versions from the list
    const filterList: any = [];

    this.paginationReset?.reset();

    this.viewVersionList.forEach((status: any) => {
      if (this.selectedStatusFilter[status.status]) {
        filterList.push(status);
      }
    });

    // check if no filter selected show initial list
    const noFilterSelected = Object.values(this.selectedStatusFilter).every(v => !v);

    if (noFilterSelected) {
      this.filteredVersion = this.viewVersionList;
    } else {
      this.filteredVersion = filterList;
    }
  }

  onClosePanel(event: any): void {
    this.selectedVersion = event.data;
    this.patchValue(this.selectedVersion);
    this.overlayPanel.hide();
  }

  closeViewMetric(): void {
    this.catalogData = [];
    this.viewData = [];
    this.metricConnector = [];
    this.viewVersionList = [];
    this.closeClicked.emit(false);
  }

  ngOnDestroy(): void {
    this.catalogSubscription.unsubscribe();
  }

  private addCheckboxes(): void {
    this.statusList.map(() => {
      const control = new FormControl();

      return (this.createMetricForm.get('statusFilter') as FormArray).push(control);
    });
  }
}
