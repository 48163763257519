<div class="container-fluid">
    <div class="row">
        <div class="d-flex justify-content-end">
            <div class="col-3 text-right">
                <button pButton type="button" class="p-button mb-0" label="{{ 'MODULES.METRICS.CLEAR_FILTER' | translate }}"
                    (click)="clearGrid()" [disabled]="!haveFilters">
                </button>
            </div>
        </div>
    </div>

    <div class="row">
        <ag-grid-angular class="ag-theme-balham" style="width: 100%; height: calc(100vh - 140px)"
            [columnDefs]="headers" [gridOptions]="gridOptions" (gridReady)="onGridReady($event)" 
            (sortChanged)="onSortChange()" (filterModified)="onFilterModified($event)" #agGrid>
        </ag-grid-angular>
    </div>
</div>
