import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
/**
 * Prefixes all requests not starting with `http[s]` with `environment.serverUrl`.
 */
@Injectable()
export class ApiPrefixInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let requestCopy = request;

    if (!/^(http|https):/i.test(request.url)) {
      requestCopy = request.clone({
        url: request.url,
        withCredentials: true,
      });
    }

    return next.handle(requestCopy);
  }
}
