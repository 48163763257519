import { Component } from '@angular/core';

import type { INoRowsOverlayAngularComp } from 'ag-grid-angular';
import type { INoRowsOverlayParams } from 'ag-grid-community';

type CustomNoRowsOverlayParams = INoRowsOverlayParams & { noRowsMessageFunc: () => string };

@Component({
  selector: 'app-custom-no-rows-overlay',
  template: `<span class="ag-overlay-no-rows-center">{{ params.noRowsMessageFunc() }}</span>`,
  styles: [],
})
export class CustomNoRowsOverlayComponent implements INoRowsOverlayAngularComp {
  params: any;

  agInit(params: CustomNoRowsOverlayParams): void {
    this.refresh(params);
  }

  refresh(params: CustomNoRowsOverlayParams): void {
    this.params = params;
  }
}
