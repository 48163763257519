import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ContractPermissionService {
  hasAddContract(): boolean {
    const permissions: any = sessionStorage.getItem('permissions');

    if (permissions?.includes('Contract Add')) {
      return true;
    }

    return false;
  }

  hasAddContractVersion(): boolean {
    const permissions: any = sessionStorage.getItem('permissions');

    if (permissions?.includes('Contract Edit')) {
      return true;
    }

    return false;
  }

  hasEditContractVersion(): boolean {
    const permissions: any = sessionStorage.getItem('permissions');

    if (permissions?.includes('Contract Edit')) {
      return true;
    }

    return false;
  }

  hasPublishContractVersion(): boolean {
    const permissions: any = sessionStorage.getItem('permissions');

    if (permissions?.includes('Contract Edit')) {
      return true;
    }

    return false;
  }

  hasRevokeContractVersion(): boolean {
    const permissions: any = sessionStorage.getItem('permissions');

    if (permissions?.includes('Contract Edit')) {
      return true;
    }

    return false;
  }

  hasEditContract(): boolean {
    const permissions: any = sessionStorage.getItem('permissions');

    if (permissions?.includes('Contract Edit')) {
      return true;
    }

    return false;
  }
}
