import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { GridOptions } from 'ag-grid-community';
import { GridApi } from 'ag-grid-enterprise';
import { Observable, Subscription } from 'rxjs';

import { CatalogActionComponent } from './action/catalog-action/catalog-action.component';
import { CellRendererService } from '@app/core/resources/cell-renderer.service';
import { FilterParamsService } from '@app/core/resources/filter-params.service';
import { FilterTextWhitespacesService } from '@app/core/resources/filter-text-whitespaces.service';
import { FilterTypeService } from '@app/core/resources/filter-type.service';
import { PaginationAggridFlexService } from '@app/core/resources/pagination-aggrid-flex.service';
import { Metrics } from '@app/slm/interface/metrics.tree';
import { CatalogPermissionService } from '@app/slm/services/catalog-permission.service';
import { CatalogService } from '@app/slm/services/catalog.service';

@Component({
  selector: 'app-catalog',
  templateUrl: './catalog.component.html',
  styleUrls: ['./catalog.component.scss'],
})
export class CatalogComponent implements OnInit, OnDestroy {
  @ViewChild('agGrid', { read: ElementRef }) gridElementRef!: ElementRef;

  @Output() closeClicked = new EventEmitter<boolean>();

  metricTreeData!: Metrics[];

  selected: any = [];

  metricsTableData: any = [];

  businessId = '';

  isCreateMetricEnabled = false;

  isNewMetric = false;

  metricConnectorsData: any = [];

  headers: any = [];

  treeData: any = [];

  grid!: GridApi;

  gridOptions: GridOptions = {
    alwaysMultiSort: true,
    headerHeight: 40,
    groupHeaderHeight: 40,
    rowHeight: 40,
    suppressContextMenu: true,
    overlayNoRowsTemplate: '<span class="ag-overlay-no-rows-center">No records found</span>',
    defaultColDef: { resizable: true },
    autoGroupColumnDef: { minWidth: 250, resizable: true },
    pagination: true,
    paginationPageSize: 50,
    paginationPageSizeSelector: [50, 100, 200],
    maxBlocksInCache: 0,
    cacheBlockSize: 50,
    serverSidePivotResultFieldSeparator: '_',
    pivotMode: false,
    rowModelType: 'serverSide',
    onFirstDataRendered(params) {
      params.api.setGridOption('defaultColDef', { autoHeight: true });
    },
    getMainMenuItems: () => {
      return this.getMainMenuItems();
    }, // custom main column header menu
  };

  haveFilters = true;

  isCatalogViewEnabled = false;

  isCreateCatalog = false;

  isCreateCatalogVersionEnabled = false;

  isEditCatalogVersionEnabled = false;

  private subscriptions: Subscription = new Subscription();

  constructor(
    private catalogService: CatalogService,
    private translateService: TranslateService,
    public catalogPermission: CatalogPermissionService,
    private filterTextWhitespacesService: FilterTextWhitespacesService,
    private filterTypeService: FilterTypeService,
    private filterParams: FilterParamsService,
    private paginationService: PaginationAggridFlexService,
    private cellRendererService: CellRendererService
  ) {
    this.metricsTableData = [];
    this.subscriptions.add(
      this.catalogService.isViewOpen$.subscribe((isViewOpen: any) => {
        this.isCatalogViewEnabled = isViewOpen || false;
      })
    );
    /**
     * adding new created metric on metric table
     * update metric count on both tree and folder
     */
    this.catalogService.createdCatalogMetric.subscribe((res: any) => {
      if (res) {
        if (res.res.businessOfferingId === this.selected.businessId) {
          this.selected.countOfChildren += 1;
        }
        if (res.isNew) {
          this.metricsTableData = [];
          this.isNewMetric = res.isNew;
          this.grid.refreshServerSide();
        }
      }
    });
  }

  ngOnInit(): void {
    this.getCatalogTreeData();
    this.getHeadersData();
  }

  // Implemented this function to handle node expansion/collapse
  toggleNodeExpand(node: any): void {
    const nodeCopy = { ...node }; // Create a copy of the node object

    nodeCopy.expanded = !nodeCopy.expanded; // Modify the copy instead of the original object
  }

  getCatalogTreeData(): void {
    this.catalogService.getMetricTreeData().subscribe((res: any) => {
      if (res) {
        this.metricTreeData = [res];

        // select root node
        [this.selected] = this.metricTreeData;

        // expand root node
        this.selected.expanded = true;
      }
    });
  }

  nodeSelect(): void {
    // expand-collapse on node
    if (this.selected.key !== '0') {
      this.selected.expanded = !this.selected.expanded;
    }

    // to check last node
    if (this.selected.children && this.selected.children.length === 0) {
      this.selected.expanded = !this.selected.expanded;
    }

    const { children } = this.selected;

    if (children.length === 0 && this.businessId !== this.selected.businessId) {
      this.businessId = this.selected.businessId;
      this.grid.refreshServerSide();
    }
  }

  onGridReady(event: any): void {
    this.grid = event.api;
    this.grid.setGridOption('serverSideDatasource', this.getGridDataFromAPI());
  }

  getHeadersData(): void {
    // clear headers before jumping-in
    this.headers = [];
    this.catalogService.getCatalogHeaders().subscribe((res: any[]) => {
      this.headers = res.map((item: any) => this.headerFormulate(item));

      const isPivotEnabled: boolean = this.headers.some((header: any) => header.pivot === true);

      this.grid.setGridOption('pivotMode', isPivotEnabled);
    });
  }

  headerFormulate(header: any): any {
    const headerObj = {
      id: header.id,
      field: header.fieldName ? header.fieldName.toLowerCase() : header.field.toLowerCase(),
      minWidth: header.minWidth,
      maxWidth: header.maxWidth,
      wrapText: header.textWrapping,
      headerName: header.displayName ? header.displayName : header.headerName,
      sortable: header.sortable,
      sort: header.sortOrder ? header.sortDirection.toLowerCase() : header.sort,
      initialSortIndex: header.sortOrder ? header.sortOrder : header.initialSortIndex,
      filter: header.filterable ? this.filterTypeService.getMeFilterType(header.fieldType) : header.filter,
      filterParams: header.filterable ? this.filterParams.getMeFilterParams(header.fieldType) : header.filterParams,
      cellRenderer:
        header.fieldName === 'action'
          ? CatalogActionComponent
          : (rowData: any) => {
              return this.cellRendererService.reportCellRenderer(rowData);
            },
      enableRowGroup: header.groupRowsBy,
      rowGroup: header.groupRowsBy,
      hide: header.groupRowsBy || header.hidden,
      pivot: header.groupColumnsBy,
      enableValue: header.enableValue,
      aggFunc: header.aggFunction,
      dataTypeFormatId: header.dataTypeFormatId,
    };

    return headerObj;
  }

  getMeSorted(dirtyData: any): any {
    if (dirtyData.length > 0) {
      const sort: any = [];

      for (let i = 0; i <= dirtyData.length - 1; i -= -1) {
        sort.push(`${dirtyData[i].colId},${dirtyData[i].sort}`);
      }

      return sort;
    }

    return '';
  }

  // prevent pagination updates on sorting when no records are available
  onSortChange(): void {
    if (!this.metricConnectorsData.length) {
      this.paginationService.setLbLastRowOnPage(this.grid, this.gridElementRef, true);
    }
  }

  getMeFiltered(filterData: any, rowGroupInfo: any): any {
    const sqlQueries: any = [];

    Object.keys(filterData).forEach(key => {
      const filter = filterData[key];
      const conditions: any = [];

      const appliedFilterData: any = {
        fieldName: key,
        filterType: filter.filterType,
      };

      if (filter.operator) {
        appliedFilterData.operator = filter.operator;

        filter.conditions.forEach((item: any) => {
          const filterVal: any = {
            type: item.type,
            start: item.filterType === 'date' ? item.dateFrom : item.filter,
          };

          if (item.type === 'inRange') {
            filterVal.end = item.filterType === 'date' ? item.dateTo : item.filterTo;
          }
          conditions.push(filterVal);
        });
      } else {
        const filterVal: any = {
          type: filter.type,
          start: filter.filterType === 'date' ? filter.dateFrom : filter.filter,
        };

        if (filter.type === 'inRange') {
          filterVal.end = filter.filterType === 'date' ? filter.dateTo : filter.filterTo;
        }
        conditions.push(filterVal);
      }

      appliedFilterData.conditions = conditions;

      sqlQueries.push(appliedFilterData);
    });

    this.haveFilters = Object.values(this.grid.getFilterModel()).length > 0;

    const filterConditions = sqlQueries;
    const payload: any = filterConditions?.length ? { filterConditions } : {};

    const agParamsRequestData: any = this.transformRowGroupInfo(rowGroupInfo);
    // Creating a new object and merging the properties of payload and rowgroupPayload into it
    const mergedPayload = { ...payload, ...agParamsRequestData };

    // This has been done as the key for the start and end row different from api end point and key does not serve it's purpose
    delete mergedPayload.startRow;
    delete mergedPayload.endRow;

    return mergedPayload;
  }

  // handling if filter only contains whitespace
  onFilterModified(e: any): void {
    this.filterTextWhitespacesService.textWhitespaceTrim(e, this.grid);
  }

  transformRowGroupInfo(rowGroupInfo: any): any {
    // Create a mapping from fieldName to an object containing id, fieldName, and aggFunc from headers
    const fieldToIdMap = this.headers.reduce((accumulator: any, header: any) => {
      accumulator[header.field] = {
        id: header.id,
        fieldName: header.field,
        aggFunction: header.aggFunc,
        formatId: header.dataTypeFormatId,
      };

      return accumulator; // Return the accumulator object for the next iteration
    }, {});

    // Transform rowGroupCols by mapping each column to its corresponding id and fieldName
    const groupByRow = rowGroupInfo.rowGroupCols.map((col: any) => {
      const mappedField = fieldToIdMap[col.field];

      // If mapping exists, replace the id and fieldName, otherwise, return the column unchanged
      return mappedField
        ? { ...col, id: mappedField.id, fieldName: mappedField.fieldName, formatId: mappedField.formatId }
        : col;
    });

    // Transform valueCols by mapping each column to its corresponding id and fieldName, and override aggFunc if available
    const valueCols = rowGroupInfo.valueCols.map((col: any) => {
      const mappedField = fieldToIdMap[col.field];

      return mappedField
        ? {
            ...col,
            id: mappedField.id,
            fieldName: mappedField.fieldName,
            aggFunction: mappedField.aggFunc || col.aggFunc,
            formatId: mappedField.formatId,
          }
        : col;
    });

    // Transform pivotCols
    const pivotColumns = rowGroupInfo.pivotCols.map((col: any) => {
      const mappedField = fieldToIdMap[col.field];

      return mappedField
        ? { ...col, id: mappedField.id, fieldName: mappedField.fieldName, formatId: mappedField.formatId }
        : col;
    });

    // Transform sortModel
    const sortModel = rowGroupInfo.sortModel.map((sort: any) => {
      const mappedField = fieldToIdMap[sort.colId.toLowerCase()];

      return mappedField ? { ...sort, colId: mappedField.fieldName } : sort;
    });

    const customParameters = [
      {
        parameter: 'BUSINESS_OFFERING_ID',
        parameterValues: [this.businessId],
      },
    ];

    // Create a new object with transformed properties
    return {
      ...rowGroupInfo,
      groupByRow,
      valueCols,
      pivotColumns,
      sortModel,
      customParameters,
    };
  }

  getGridDataFromAPI(): any {
    return {
      getRows: (agParams: any) => {
        const params: any = {
          offset: agParams.request.startRow,
          limit: agParams.request.endRow - agParams.request.startRow,
          sort: this.getMeSorted(agParams.request.sortModel),
        };

        // updating cache to be in sync with page size
        this.grid.setGridOption('cacheBlockSize', this.grid.paginationGetPageSize());

        this.catalogService
          .getCatalogData(this.getMeFiltered(agParams.request.filterModel, agParams.request), params)
          .subscribe((res: any) => {
            this.metricConnectorsData = res.elements;
            if (!res.elements.length) {
              this.grid.showNoRowsOverlay();
            } else {
              this.grid.hideOverlay();
            }

            // calculating subheaders and its dataset
            if (res.reportColumnRoot && res.reportColumnRoot.length > 0) {
              const uniqueComplianceHeaders = new Set(this.headers.map((header: any) => header.field));

              res.reportColumnRoot.forEach((item: any) => {
                if (!uniqueComplianceHeaders.has(item.fieldName)) {
                  uniqueComplianceHeaders.add(item.fieldName);
                  this.headers.push(item);

                  // removing duplicate fields
                  const filteredHeaders = this.headers.filter(
                    (element: any) => element.field !== item.fieldName?.toLowerCase()
                  );

                  this.headers = filteredHeaders;
                }
              });
              this.headers = this.headers.map((header: any) => this.headerFormulate(header));
            }

            const tableData: any = res.elements.map((row: any) => {
              const rowDataEntry: any = {};
              let attributes: any = [];

              row.reportColumnData.forEach((colData: any) => {
                rowDataEntry[colData.fieldName.toLowerCase()] = colData.formattedValue;
                // Assign the value to fieldName by adding Value identifier to the key->fieldName
                rowDataEntry[`${colData.fieldName.toLowerCase()}Value`] = colData.value;

                // passing field identifier in attributes
                if (colData.reportAttributeDtos) {
                  const newAttr = colData.reportAttributeDtos?.map((v: any) => ({
                    ...v,
                    field: colData.fieldName.toLowerCase(),
                  }));

                  attributes = [...attributes, ...newAttr];
                }
                rowDataEntry.attributes = attributes;
              });

              return rowDataEntry;
            });

            agParams.success({
              rowData: tableData,
              rowCount: res.totalElements,
            });

            // auto-resize all columns by default
            // check with grouping and spanning
            agParams.columnApi.autoSizeAllColumns();

            // Workaround to show the actual number of rows for a given page
            this.paginationService.setLbLastRowOnPage(this.grid, this.gridElementRef);
          });
      },
    };
  }

  // custom main column header menu Items
  getMainMenuItems(): [] {
    const customResetCol: any = [
      'pinSubMenu',
      'separator',
      {
        name: 'Reset Columns',
        action: () => {
          this.grid.autoSizeAllColumns();
          this.grid.resetColumnState();
        },
      },
    ];

    return customResetCol;
  }

  clearGrid(): void {
    this.grid.setFilterModel(null);
  }

  close(): void {
    this.isCatalogViewEnabled = false;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  openCreateMetricForm(): void {
    const node = { name: this.selected.name, id: this.selected.businessId };

    this.catalogService.getSelectedNodeData(node);
    this.isCreateMetricEnabled = true;
  }

  private disableRecursive(node: any): void {
    const nodeSelected = node;

    if (!node.countOfChildren) {
      nodeSelected.selectable = false;
    }
    node.children.forEach((childNode: any) => {
      this.disableRecursive(childNode);
    });
  }
}
