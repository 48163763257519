import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { IProvidedFilter } from 'ag-grid-community';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class FilterTextWhitespacesService {
  constructor(private messageService: MessageService, private translateService: TranslateService) {}

  textWhitespaceTrim(e: any, grid: any): void {
    const coldId = e.column.colId;
    const searchVal = (e.filterInstance as unknown as IProvidedFilter).getModelFromUi();

    // handling for 2 conditions
    if (searchVal.operator) {
      searchVal.conditions.forEach((item: any) => {
        if (item.filter && !item.filter.trim()) {
          // getting filter Dom Element using colId
          const filterInstance: any = grid.getFilterInstance(coldId);

          filterInstance.eButtonsPanel.children[0].disabled = true;

          // warning message
          this.messageService.add({
            severity: 'warn',
            summary: this.translateService.instant('MODULES.REPORTS.FILTER_FAILURE'),
          });
        }
      });
    } else if (searchVal.filter && !searchVal.filter.trim()) {
      const filterInstance: any = grid.getFilterInstance(coldId);

      // warning message
      filterInstance.eButtonsPanel.children[0].disabled = true;
      this.messageService.add({
        severity: 'warn',
        summary: this.translateService.instant('MODULES.REPORTS.FILTER_FAILURE'),
      });
    }
  }
}
