import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FilterTypeService {
  getMeFilterType(type: string): string {
    if (
      type === 'String' ||
      type === 'Rich Text' ||
      type === 'Value Set' ||
      type === 'Hierarchy' ||
      type === 'Text' ||
      type === 'Email recipients'
    ) {
      return 'agTextColumnFilter';
    }
    if (type === 'Number') {
      return 'agNumberColumnFilter';
    }
    if (type === 'Date' || type === 'Date and Time' || type === 'Month' || type === 'Year') {
      return 'agDateColumnFilter';
    }

    return '';
  }
}
