import { Component, Input } from '@angular/core';

import { LayoutService } from '../services/layout.service';
import { MenuService } from '../services/menu.service';

@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.scss'],
})
export class ConfigComponent {
  @Input() minimal = false;

  scales: number[] = [12, 13, 14, 15, 16];

  constructor(public layoutService: LayoutService, public menuService: MenuService) {}

  // eslint-disable-next-line @typescript-eslint/member-ordering
  get visible(): boolean {
    return this.layoutService.state.configSidebarVisible;
  }

  set visible(_val: boolean) {
    this.layoutService.state.configSidebarVisible = _val;
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  get scale(): number {
    return this.layoutService.config?.scale;
  }

  set scale(_val: number) {
    this.layoutService.config.scale = _val;
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  get menuMode(): string {
    return this.layoutService.config.menuMode;
  }

  set menuMode(_val: string) {
    this.layoutService.config.menuMode = _val;
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  get inputStyle(): string {
    return this.layoutService.config.inputStyle;
  }

  set inputStyle(_val: string) {
    this.layoutService.config.inputStyle = _val;
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  get ripple(): boolean {
    return this.layoutService.config.ripple;
  }

  set ripple(_val: boolean) {
    this.layoutService.config.ripple = _val;
  }

  onConfigButtonClick(): void {
    this.layoutService.showConfigSidebar();
  }

  changeTheme(theme: string, colorScheme: string): void {
    const themeLink = <HTMLLinkElement>document.getElementById('theme-css');
    const newHref = themeLink.getAttribute('href')!.replace(this.layoutService.config.theme, theme);

    // Will check the lint issue
    // eslint-disable-next-line no-unused-expressions
    this.layoutService.config.colorScheme;
    // eslint-disable-line @typescript-eslint/no-non-null-assertion
    this.replaceThemeLink(newHref, () => {
      this.layoutService.config.theme = theme;
      this.layoutService.config.colorScheme = colorScheme;
      this.layoutService.onConfigUpdate();
    });
  }

  replaceThemeLink(href: string, onComplete: any): void {
    const id = 'theme-css';
    const themeLink = <HTMLLinkElement>document.getElementById('theme-css');
    const cloneLinkElement = <HTMLLinkElement>themeLink.cloneNode(true);

    cloneLinkElement.setAttribute('href', href);
    cloneLinkElement.setAttribute('id', `${id}-clone`);

    themeLink.parentNode!.insertBefore(cloneLinkElement, themeLink.nextSibling);

    cloneLinkElement.addEventListener('load', () => {
      themeLink.remove();
      cloneLinkElement.setAttribute('id', id);
      onComplete();
    });
  }

  decrementScale(): void {
    // eslint-disable-next-line
    this.scale--;
    this.applyScale();
  }

  incrementScale(): void {
    // eslint-disable-next-line
    this.scale++;
    this.applyScale();
  }

  applyScale(): void {
    document.documentElement.style.fontSize = `${this.scale}px`;
  }
}
