import { Component } from '@angular/core';

import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-checkbox-renderer',
  template: `
    <div class="checkbox-container">
      <input
        type="checkbox"
        [checked]="isChecked"
        (change)="onCheckboxChange($event)"
        [disabled]="isDisabled"
        class="custom-checkbox"
        title="Select"
      />
      <span class="cell-value">{{ params?.value }}</span>
    </div>
  `,
  styles: [
    `
      .checkbox-container {
        display: flex;
        align-items: center;
      }

      /* Initial unchecked state styling */
      .custom-checkbox {
        width: 16px;
        height: 16px;
        cursor: pointer;
        border: 1px solid #888888;
        background-color: var(--ag-background-color, #f0f0f0);
        border-radius: 3px;
        margin-right: 8px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
      }

      /* Checked state with visible tick */
      .custom-checkbox:checked {
        background-color: var(--primary-color, #004f6f); /* Set the primary background color */
        border-color: var(--primary-color, #004f6f); /* Change border color */
        background-image: url('/assets/icons/table/checkbox.svg');
        background-size: 10px 10px; /* Adjust the size of the checkmark */
        background-position: center; /* Center the checkmark */
        background-repeat: no-repeat;
      }

      /* Disabled state with reduced opacity */
      .custom-checkbox:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }

      .cell-value {
        flex-grow: 1;
      }
    `,
  ],
})
export class CheckboxRendererComponent implements ICellRendererAngularComp {
  public isChecked = false;

  public isDisabled = false;

  public params: ICellRendererParams | undefined;

  agInit(params: ICellRendererParams): void {
    this.params = params;

    // Ensure isChecked is always a boolean
    this.isChecked = !!params.node.isSelected();

    // Ensure isDisabled is always a boolean
    this.isDisabled = this.shouldDisableCheckbox(params.data);
  }

  refresh(params: ICellRendererParams): boolean {
    // Ensure isChecked is always a boolean
    this.isChecked = !!params.node.isSelected();

    // Ensure isDisabled is always a boolean
    this.isDisabled = this.shouldDisableCheckbox(params.data);

    return true;
  }

  onCheckboxChange(event: Event): void {
    const checkboxChecked = (event.target as HTMLInputElement).checked;

    // If the checkbox is not disabled, set the selected state of the row
    if (!this.isDisabled) {
      this.params?.node.setSelected(checkboxChecked);
    }
  }

  private shouldDisableCheckbox(rowData: any): boolean {
    // Return true if the rowData's metricversionstatus is 'Revoked', otherwise false
    return rowData?.metricversionstatus === 'Revoked';
  }
}
