<p-blockUI [blocked]="loading" styleClass="loader-overlay">
  <p-progressSpinner></p-progressSpinner>
</p-blockUI>

<div class="container-fluid" *ngIf="!loading">
  <div class="row">
    <!-- tree structure -->
    <div class="col-3 col-sm-3">
      <p-tree styleClass="tree-height" [value]="metricTreeData" selectionMode="single" [(selection)]="selected"
        (onNodeSelect)="nodeSelect()" emptyMessage="{{ 'AUTH.DEFAULT_EMPTY_MSG' | translate }}">
        <ng-template let-node pTemplate="default">
          <div class="d-flex w-100">
            <i [ngClass]="
                node.expanded
                  ? 'pi pi-folder-open mr-2 my-auto tree-folder-icon'
                  : 'pi pi-folder mr-2 my-auto tree-folder-icon'
              "></i>
            <div>
              <h6>{{ node.name }}</h6>
              <p class="mb-0">
                ({{ node.countOfChildren ? node.countOfChildren : '0' }}
                {{
                node.countOfChildren > 1
                ? node.childLevelNamePlural
                : node.childLevelName
                }})
              </p>
            </div>
          </div>
        </ng-template>
      </p-tree>
    </div>

    <div [ngClass]="selected?.children?.length === 0 && entityList?.length ? 'col-7' : 'col-9' ">
      <!-- folder structure -->
      <ng-container *ngIf="selected?.children?.length > 0">
        <div class="row">
          <ng-container *ngFor="let x of selected.children">
            <div class="col-3 pt-0">
              <div class="card text-center p-2">
                <i class="pi pi-folder tree-folder-icon"></i>
                <h6 class="mt-2 mb-1">{{ x.name }}</h6>
                <p class="my-1">
                  {{ x.levelName }} ({{
                  x.countOfChildren ? x.countOfChildren : '0'
                  }}
                  {{
                  x.countOfChildren > 1
                  ? x.childLevelNamePlural
                  : x.childLevelName
                  }})
                </p>
              </div>
            </div>
          </ng-container>
        </div>
      </ng-container>

      <!-- table structure -->
      <ng-container *ngIf="selected?.children?.length === 0">
        <p-table #paginationReset styleClass="p-datatable-sm" [value]="metricsTableData"
          [(selection)]="selectedTableRow" [totalRecords]="totalRecords" (onLazyLoad)="triggerPagination($event)"
          [lazy]="true" [scrollable]="true" selectionMode="multiple" dataKey="id" [selectionPageOnly]="true"
          [paginator]="true" [rows]="size" [rowsPerPageOptions]="[50, 100, 200]" [showCurrentPageReport]="true"
          currentPageReportTemplate="{{
            'MODULES.PAGINATION.SHOWING' | translate
          }} {first} {{ 'MODULES.PAGINATION.TO' | translate }} {last} {{
            'MODULES.PAGINATION.OF' | translate
          }} {{ totalRecords }} {{ 'MODULES.PAGINATION.ENTRIES' | translate }}" [scrollHeight]="'calc(100vh - 190px)'">
          <ng-template pTemplate="header">
            <tr>
              <th>
                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
              </th>
              <th style="min-width: 300px">
                {{ 'MODULES.METRICS.METRIC' | translate }}
              </th>
              <th style="min-width: 150px">
                {{ 'MODULES.METRICS.STATUS' | translate }}
              </th>
              <th style="min-width: 150px">
                {{ 'MODULES.METRICS.COVERAGE' | translate }}
              </th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-metricsTableData>
            <tr>
              <td>
                <p-tableCheckbox [value]="metricsTableData"></p-tableCheckbox>
              </td>
              <td>{{ metricsTableData.name }}</td>
              <td>
                <span [ngClass]="
                    metricsTableData.status === 'Published'
                      ? 'success-chip'
                      : metricsTableData.status === 'Draft'
                      ? 'warning-chip'
                      : 'danger-chip'
                  ">
                  {{
                  metricsTableData.status ? metricsTableData.status : 'N/A'
                  }}
                </span>
              </td>
              <td>
                <img [src]="
                    metricsTableData.coverage === 'Current'
                      ? 'assets/icons/metrics/current.svg'
                      : metricsTableData.coverage === 'Not current'
                      ? 'assets/icons/metrics/not current.svg'
                      : 'assets/icons/metrics/na.svg'
                  " alt="view icon" />
                {{
                metricsTableData.coverage ? metricsTableData.coverage : 'N/A'
                }}
              </td>
            </tr>
          </ng-template>

          <!-- no records msg -->
          <ng-template pTemplate="emptymessage">
            <tr>
              <td class="text-center" [attr.colSpan]="8">
                {{ 'MODULES.EMPTY_TABLE_MSG' | translate }}
              </td>
            </tr>
          </ng-template>
        </p-table>
      </ng-container>
    </div>

    <!-- provider entity list -->
    <ng-container *ngIf="selected?.children?.length === 0 && entityList?.length">
      <div class="col-2">
        <form [formGroup]="providerForm">
          <p-listbox formControlName="selectedEntity" [options]="entityList" optionLabel="name" [checkbox]="true"
            [multiple]="true" [filter]="true" [listStyle]="{'max-height':'calc(100vh - 183px)'}" appendTo="body">

            <!-- checkbox-select-all text -->
            <ng-template pTemplate="filter">
              <span class="fw-bold" translate>MODULES.CONTRACTS.SELECT_ALL</span>
            </ng-template>
          </p-listbox>
        </form>
      </div>
    </ng-container>

    <!-- btn section -->
    <div class="p-dialog-footer py-0" *ngIf="selected?.children">
      <div class="row justify-content-end">
        <div class="col-12">
          <button pButton type="submit" class="p-button me-3" (click)="copyMetrics()" [disabled]="copyBtnDisable()">
            <span class="p-button-label">{{ 'MODULES.CONTRACTS.COPY_TO_CONTRACT' | translate }}</span>
            <span class="p-custom-badge">{{ selectedTableRow.length }}</span>
          </button>

          <button pButton type="submit" class="p-button-outlined" style="width: 110px"
            label="{{ 'MODULES.VIEW_METRICS.CLOSE' | translate }}" (click)="closePopup()"></button>
        </div>
      </div>
    </div>
  </div>
</div>