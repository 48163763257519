<div class="right-sidebar-header">
  <h5 class="px-4 fw-bold d-inline" translate>MODULES.CREATE_METRIC.ADD_TITLE</h5>
  <p-divider styleClass="mb-0"></p-divider>
</div>

<form [formGroup]="createMetricForm">
  <div class="px-4">
    <div class="row">
      <!-- metric id -->
      <div class="col-12">
        <span class="w-100 p-float-label my-2">
          <input class="w-100 p-inputtext-sm" type="text" pInputText formControlName="id" />
          <label for="id">{{
            'MODULES.VIEW_METRICS.METRIC_ID' | translate
            }}</label>
        </span>
        <div *ngIf="createMetricForm.get('id')?.touched">
          <small *ngIf="createMetricForm.get('id')?.errors?.['required']" class="text-danger">
            {{ 'VALIDATION.REQUIRED' | translate }}
          </small>
          <small *ngIf="createMetricForm.get('id')?.errors?.['maxlength']" class="text-danger">
            {{ 'VALIDATION.MAXLENGTH' | translate : '{requiredLength: 20}' }}
          </small>
        </div>
      </div>

      <!-- metric name -->
      <div class="col-12">
        <span class="w-100 p-float-label my-1">
          <input class="w-100 p-inputtext-sm" type="text" pInputText formControlName="name" />
          <label for="name">{{
            'MODULES.CREATE_METRIC.METRIC_NAME' | translate
            }}</label>
        </span>
        <div *ngIf="createMetricForm.get('name')?.touched">
          <small *ngIf="createMetricForm.get('name')?.errors?.['required']" class="text-danger">
            {{ 'VALIDATION.REQUIRED' | translate }}
          </small>
          <small *ngIf="createMetricForm.get('name')?.errors?.['maxlength']" class="text-danger">
            {{ 'VALIDATION.MAXLENGTH' | translate : '{requiredLength: 30}' }}
          </small>
        </div>
      </div>

      <!-- description -->
      <div class="col-12">
        <span class="w-100 p-float-label my-1">
          <textarea class="w-100" pInputTextarea formControlName="description"></textarea>
          <label for="description">{{
            'MODULES.VIEW_METRICS.DESCRIPTION' | translate
            }}</label>
        </span>
        <div *ngIf="createMetricForm.get('description')?.touched">
          <small *ngIf="createMetricForm.get('description')?.errors?.['maxlength']" class="text-danger">
            {{ 'VALIDATION.MAXLENGTH' | translate : '{requiredLength: 1000}' }}
          </small>
        </div>
      </div>

      <!-- metric connector -->
      <div class="col-12">
        <span class="w-100 p-float-label my-1">
          <p-autoComplete styleClass="w-100" inputStyleClass="w-100" [suggestions]="connectorList" field="name"
            (completeMethod)="metricConnectorData($event)" [showEmptyMessage]="true"
            emptyMessage="{{ 'AUTH.DEFAULT_EMPTY_MSG' | translate }}" [showClear]="true"
            formControlName="metricConnector" (onSelect)="onSelectConnector()" (onClear)="onResetConnector()">
          </p-autoComplete>
          <label for="metricConnector">{{
            'MODULES.METRICS.METRIC_CONNECTOR' | translate
            }}</label>
        </span>
        <small class="font-12">
          <b>{{ 'VALIDATION.AUTOCOMPLETE_MINLENGTH' | translate }}</b>
        </small>
        <p class="mt-3 mb-0" *ngIf="isSelectedConnector">
          {{ 'MODULES.VIEW_METRICS.PARAMETER' | translate }}:
          {{
          createMetricForm.get('metricConnector')?.value['paramsAsString']
          ? createMetricForm.get('metricConnector')?.value['paramsAsString']
          : 'N/A'
          }}
        </p>
      </div>

      <!-- Offering -->
      <div class="col-12">
        <span class="w-100 p-float-label my-1">
          <input class="w-100 p-inputtext-sm" type="text" pInputText formControlName="offeringId"
            [attr.disabled]="true" />
          <label for="businessService">{{
            'MODULES.CREATE_METRIC.OFFERING' | translate
            }}</label>
        </span>
        <div *ngIf="createMetricForm.get('businessService')?.touched">
          <small *ngIf="createMetricForm.get('businessService')?.errors?.['required']" class="text-danger">
            {{ 'VALIDATION.REQUIRED' | translate }}
          </small>
        </div>
      </div>

      <!-- statement -->
      <div class="col-12">
        <span class="w-100 p-float-label my-1">
          <textarea class="w-100" pInputTextarea formControlName="statement"></textarea>
          <label for="statement">{{
            'MODULES.CREATE_METRIC.STATEMENT' | translate
            }}</label>
        </span>
        <div *ngIf="createMetricForm.get('statement')?.touched">
          <small *ngIf="createMetricForm.get('statement')?.errors?.['required']" class="text-danger">
            {{ 'VALIDATION.REQUIRED' | translate }}
          </small>
          <small *ngIf="createMetricForm.get('statement')?.errors?.['maxlength']" class="text-danger">
            {{ 'VALIDATION.MAXLENGTH' | translate : '{requiredLength: 250}' }}
          </small>
        </div>
      </div>

      <!-- target condition -->
      <div class="col-12">
        <span class="w-100 p-float-label my-1">
          <p-dropdown styleClass="w-full" [options]="conditionList" formControlName="condition" optionLabel="name"
            optionValue="id" placeholder="{{ 'MODULES.CREATE_METRIC.CONDITION' | translate }}"
            emptyMessage="{{ 'AUTH.DEFAULT_EMPTY_MSG' | translate }}">
          </p-dropdown>
          <label for="target">{{
            'MODULES.CREATE_METRIC.CONDITION' | translate
            }}</label>
        </span>
        <div *ngIf="createMetricForm.get('condition')?.touched">
          <small *ngIf="createMetricForm.get('condition')?.errors?.['required']" class="text-danger">
            {{ 'VALIDATION.REQUIRED' | translate }}
          </small>
        </div>
      </div>

      <!-- format -->
      <div class="col-6">
        <span class="w-100 p-float-label my-1">
          <p-dropdown styleClass="w-full" [options]="formatList" formControlName="targetFormat" optionLabel="name"
            optionValue="id" placeholder="{{ 'MODULES.CREATE_METRIC.FORMAT' | translate }}"
            emptyMessage="{{ 'AUTH.DEFAULT_EMPTY_MSG' | translate }}" (onChange)="updateSubFormat()"
            optionDisabled="inactive">
          </p-dropdown>
          <label for="target">{{
            'MODULES.CREATE_METRIC.FORMAT' | translate
            }}</label>
        </span>
      </div>

      <!-- sub format -->
      <div class="col-6">
        <span class="w-100 p-float-label my-1">
          <p-dropdown styleClass="w-full" [options]="subFormatList" formControlName="subTargetFormat" optionLabel="name"
            optionValue="id" placeholder="{{ 'MODULES.CREATE_METRIC.SUB_FORMAT' | translate }}" [disabled]="!createMetricForm.get('targetFormat')?.value"
            emptyMessage="{{ 'AUTH.DEFAULT_EMPTY_MSG' | translate }}">
          </p-dropdown>
          <label for="target">{{
            'MODULES.CREATE_METRIC.SUB_FORMAT' | translate
            }}</label>
        </span>
        <div *ngIf="createMetricForm.get('subTargetFormat')?.touched">
          <small *ngIf="createMetricForm.get('subTargetFormat')?.errors?.['required']" class="text-danger">
            {{ 'VALIDATION.REQUIRED' | translate }}
          </small>
        </div>
      </div>

      <!-- period -->
      <div class="col-12">
        <span class="w-100 p-float-label my-1">
          <p-dropdown styleClass="w-full" [options]="periodList" formControlName="period" optionLabel="name"
            optionValue="id" placeholder="{{ 'MODULES.CREATE_METRIC.PERIOD' | translate }}"
            emptyMessage="{{ 'AUTH.DEFAULT_EMPTY_MSG' | translate }}">
          </p-dropdown>
          <label for="period">{{
            'MODULES.CREATE_METRIC.PERIOD' | translate
            }}</label>
        </span>
        <div *ngIf="createMetricForm.get('period')?.touched">
          <small *ngIf="createMetricForm.get('period')?.errors?.['required']" class="text-danger">
            {{ 'VALIDATION.REQUIRED' | translate }}
          </small>
        </div>
      </div>
    </div>
  </div>

  <!-- version details -->
  <h5 translate class="mt-4 px-4">MODULES.CREATE_METRIC.VERSION_DETAILS</h5>
  <p-divider></p-divider>
  
  <div class="px-4">
    <div class="row">
      <!-- version name -->
      <div class="col-12">
        <span class="w-100 p-float-label my-1">
          <input class="w-100 p-inputtext-sm" type="text" pInputText formControlName="versionName" />
          <label for="versionName">{{
            'MODULES.CREATE_METRIC.VERSION_NAME' | translate
            }}</label>
        </span>
        <div *ngIf="createMetricForm.get('versionName')?.touched">
          <small *ngIf="createMetricForm.get('versionName')?.errors?.['required']" class="text-danger">
            {{ 'VALIDATION.REQUIRED' | translate }}
          </small>
        </div>
      </div>

      <!-- target -->
      <div class="col-12">
        <span class="w-100 p-float-label my-1">
          <p-inputNumber mode="decimal" [minFractionDigits]="1" formControlName="target"
            (onBlur)="getTagetValue()"></p-inputNumber>
          <label for="target">{{
            'MODULES.VIEW_METRICS.TARGET' | translate
            }}</label>
        </span>
        <div *ngIf="createMetricForm.get('target')?.touched">
          <small *ngIf="createMetricForm.get('target')?.errors?.['required']" class="text-danger">
            {{ 'VALIDATION.REQUIRED' | translate }}
          </small>
        </div>
      </div>

      <!-- expected -->
      <div class="col-12">
        <span class="w-100 p-float-label my-1">
          <p-inputNumber mode="decimal" [minFractionDigits]="1" formControlName="expected"></p-inputNumber>
          <label for="expected">{{
            'MODULES.VIEW_METRICS.EXPECTED' | translate
            }}</label>
        </span>
        <div *ngIf="createMetricForm.get('expected')?.touched">
          <small *ngIf="createMetricForm.get('expected')?.errors?.['required']" class="text-danger">
            {{ 'VALIDATION.REQUIRED' | translate }}
          </small>
        </div>
      </div>

      <!-- start date -->
      <div class="col-12">
        <span class="w-100 p-float-label my-1">
          <p-calendar styleClass="w-100" formControlName="startDate" [showIcon]="true" dateFormat="dd-mm-yy"
            [minDate]="minStartDate" (onSelect)="onSelectStartDate($event)" [readonlyInput]="true" appendTo="body">
          </p-calendar>
          <label for="startDate">{{
            'MODULES.VIEW_METRICS.START_DATE' | translate
            }}</label>
        </span>
        <div *ngIf="createMetricForm.get('startDate')?.touched">
          <small *ngIf="createMetricForm.get('startDate')?.errors?.['required']" class="text-danger">
            {{ 'VALIDATION.REQUIRED' | translate }}
          </small>
        </div>
      </div>

      <!-- end date -->
      <div class="col-12">
        <span class="w-100 p-float-label my-1">
          <p-calendar styleClass="w-100" formControlName="endDate" [showIcon]="true" [minDate]="minEndDate"
            [maxDate]="maxEndDate" dateFormat="dd-mm-yy" [readonlyInput]="true" [defaultDate]="minEndDate"
            appendTo="body">
          </p-calendar>
          <label for="endDate">{{
            'MODULES.VIEW_METRICS.END_DATE' | translate
            }}</label>
        </span>
        <div *ngIf="createMetricForm.get('endDate')?.touched">
          <small *ngIf="createMetricForm.get('endDate')?.errors?.['required']" class="text-danger">
            {{ 'VALIDATION.REQUIRED' | translate }}
          </small>
        </div>
      </div>

      <!-- version statement -->
      <div class="col-12">
        <span class="w-100 p-float-label my-1">
          <textarea class="w-100" pInputTextarea formControlName="versionStatement"></textarea>
          <label for="versionStatement">{{
            'MODULES.CONTRACTS.STATEMENT' | translate
            }}</label>
        </span>
        <div *ngIf="createMetricForm.get('versionStatement')?.touched">
          <small *ngIf="createMetricForm.get('versionStatement')?.errors?.['maxlength']" class="text-danger">
            {{ 'VALIDATION.MAXLENGTH' | translate : '{requiredLength: 250}' }}
          </small>
        </div>
      </div>
    </div>

    <!-- api error msg block -->
    <ng-container *ngIf="errorMsgList?.length">
      <ul class="ps-3">
        <li class="text-danger" *ngFor="let msg of errorMsgList">{{ msg }}</li>
      </ul>
    </ng-container>

    <div class="row mt-2">
      <div class="col-4 col-sm-4">
        <button pButton type="submit" label="{{ 'MODULES.DISTRIBUTIONS.CANCEL' | translate }}" class="p-button-outlined"
          style="width: 110px" (click)="resetForm()"></button>
      </div>
      <div class="col-4 col-sm-4 text-center">
        <button pButton type="submit" label="{{ 'MODULES.DISTRIBUTIONS.SAVE' | translate }}" class="p-button"
          style="width: 110px" (click)="saveNewMetric()"></button>
      </div>
      <div class="col-4 col-sm-4 text-right">
        <button pButton type="submit" label="{{ 'MODULES.CREATE_METRIC.PUBLISH' | translate }}" class="p-button"
          style="width: 110px" (click)="cofirmPublish($event)"></button>
      </div>
    </div>
  </div>
</form>

<p-confirmPopup></p-confirmPopup>
