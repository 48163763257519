<div class="right-sidebar-header">
    <h5 class="px-4 fw-bold d-inline" translate>MODULES.DATA.HIERARCHY_MANAGEMENT</h5>
    <p-divider styleClass="mb-0"></p-divider>
</div>

<div class="px-4">
    <!-- Root Node Details -->
    <ng-container *ngIf="selectedNodeData.key === '0'">
        <div class="row">
            <!-- hierarchy name -->
            <div class="col-4 mb-1">
                <label class="fw-bold" translate>MODULES.DATA.MANAGE.NAME</label>
                <div class="mt-1">
                    {{ hierarchyData?.name }}
                    <p-skeleton *ngIf="!hierarchyData?.name"></p-skeleton>
                </div>
            </div>
        
            <!-- hierarchy level count -->
            <div class="col-4 mb-1">
                <label class="fw-bold" translate>MODULES.DATA.MANAGE.COUNT_LEVELS</label>
                <div class="mt-1">
                    {{ hierarchyData?.parameters?.levelNames?.length }}
                    <p-skeleton *ngIf="!hierarchyData?.parameters?.levelNames"></p-skeleton>
                </div>
            </div>
        
            <!-- hierarchy levels -->
            <div class="col-4 mb-1">
                <label class="fw-bold" translate>MODULES.DATA.MANAGE.LEVELS</label>
                <div class="mt-1">
                    <ng-container *ngIf="hierarchyData?.parameters?.levelNames">
                        <ng-container *ngIf="hierarchyData?.parameters?.levelNames?.length">
                            <ng-container *ngFor="let x of hierarchyData?.parameters?.levelNames">
                                <li>{{ x.name || 'N/A' }}</li>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="!hierarchyData?.parameters?.levelNames?.length">N/A</ng-container>
                    </ng-container>
                    <p-skeleton *ngIf="!hierarchyData?.parameters?.levelNames"></p-skeleton>
                </div>
            </div>
        
            <!-- number of nodes -->
            <div class="col-4 mb-1">
                <label class="fw-bold" translate>MODULES.DATA.MANAGE.NODES</label>
                <div class="mt-1">
                    {{ rootNodeData?.numberOfNodes }}
                    <p-skeleton *ngIf="!rootNodeData?.numberOfNodes"></p-skeleton>
                </div>
            </div>
    
            <!-- data soutce -->
            <div class="col-4 mb-1">
                <label class="fw-bold" translate>MODULES.DATA.MANAGE.DATA_SOURCE</label>
                <div class="mt-1">
                    <ng-container *ngIf="rootNodeData?.numberOfNodes">Manual</ng-container>
                    <p-skeleton *ngIf="!rootNodeData?.numberOfNodes"></p-skeleton>
                </div>
            </div>
        </div>
    </ng-container>

    <!-- parent and leaf Node Details -->
    <ng-container *ngIf="selectedNodeData.key !== '0'">
        <div class="row">
            <!-- Parent -->
            <div class="col-4 mb-1">
                <label class="fw-bold" translate>MODULES.DATA.HIERARCHIES.PARENT</label>
                <div class="mt-1 text-break">
                    {{ hierarchyData?.parentName }}
                    <p-skeleton *ngIf="!hierarchyData?.parentName"></p-skeleton>
                </div>
            </div>

            <!--Name -->
            <div class="col-4 mb-1">
                <label class="fw-bold text-break" translate>MODULES.DISTRIBUTIONS.VIEW_DISTRIBUTIONS.NAME</label>
                <div class="mt-1 text-break">
                    {{ hierarchyData?.name }}
                    <p-skeleton *ngIf="!hierarchyData?.name"></p-skeleton>
                </div>
            </div>
        </div>
    </ng-container>
</div>

<!-- custom fields Details -->
<h5 translate class="mt-2 px-4">MODULES.DATA.HIERARCHIES.NODE_UDF</h5>
<p-divider styleClass="mb-0"></p-divider>

<ng-container *ngIf="customFields">
    <div class="px-4">
        <div class="row mt-2">
            <ng-container *ngIf="customFields?.length">
                <ng-container *ngFor="let field of customFields">
                    <div class="col-4 mb-1">
                        <label class="fw-bold text-break" translate>{{field.name}}</label>
                        <div class="mt-1">
                            <span class=" text-break">
                                {{ field?.value ? field?.value : 'N/A' }}
                            </span>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="!customFields?.length">
                <div class="col-12 mb-1" translate>MODULES.DATA.HIERARCHIES.NO_UDF_MSG</div>
            </ng-container>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="!customFields">
    <div class="row px-4 mt-2">
        <div class="col-4 mb-1" *ngFor="let item of [].constructor(6);">
            <p-skeleton></p-skeleton>
        </div>
    </div>
</ng-container>

<!-- footer section -->
<div class="row mt-2 px-4">
    <div class="col-6 col-sm-6">
      <button pButton type="button" label="{{ 'ABOUT.CLOSE' | translate }}" class="p-button-outlined"
        style="width: 110px" (click)="closeViewDetails()"></button>
    </div>
</div>
