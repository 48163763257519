export const environment = {
  production: false,
  name: 'dev',
  SLMUrl: 'https://backend.dev.development.amasol.com/amasol/api',
  distributionUrl: 'https://distribution.dev.development.amasol.com/amasol/distribution',
  defaultLanguage: 'en-US',
  supportedLanguages: [
    {
      label: 'German',
      value: 'de-DE',
    },
    {
      label: 'English',
      value: 'en-US',
    },
  ],
};
