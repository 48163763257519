import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Subject } from 'rxjs';

import { environment } from '@env/environment';

const routes = {
  metricConnectors: `${environment.SLMUrl}/metricconnectors`,
  metricConnectorDetails: `${environment.SLMUrl}/metricconnectors/details/`,
  metricConnectorsHeader: `${environment.SLMUrl}/reports/Metric%20Connectors/header`,
  reportMetricConnectorsData: `${environment.SLMUrl}/reports/Metric%20Connectors/data`,
};

@Injectable({
  providedIn: 'root',
})
export class MetricConnectorsService {
  viewData = new BehaviorSubject<any>('');

  isViewMetricConnectorsSidebar = new Subject<any>();

  isViewOpen$ = this.isViewMetricConnectorsSidebar.asObservable();

  constructor(private httpClient: HttpClient) {}

  toogleViewSidebar(data: any): void {
    this.isViewMetricConnectorsSidebar.next(data);
  }

  getViewData(data: any): void {
    this.viewData.next(data);
  }

  getMetricConnectorsData(page?: number, size?: number): any {
    return this.httpClient.get(`${routes.metricConnectors}?page=${page}&size=${size}`);
  }

  getViewMetricConnector(id: number): any {
    return this.httpClient.get(`${routes.metricConnectorDetails}${id}`);
  }

  getMetricConnectorHeader(params: any = {}): any {
    return this.httpClient.get(`${routes.metricConnectorsHeader}`, { params });
  }

  reportMetricConnectorsData(body?: any, params: any = {}): any {
    return this.httpClient.post(`${routes.reportMetricConnectorsData}`, body, { params });
  }
}
