import { Component, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import { CredentialsService } from '@app/core/services/credentials.service';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
})
export class ConfirmationComponent {
  @Output() closeClicked = new EventEmitter<boolean>();

  constructor(public credentialsService: CredentialsService, private router: Router) {}

  triggerLogout(): void {
    this.credentialsService.setCredentials();
    this.router.navigate(['/auth/login']);
  }

  closeLogout(): void {
    this.closeClicked.emit(false);
  }
}
