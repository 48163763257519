import { Component, NgZone } from '@angular/core';
import { Router } from '@angular/router';

import { LoaderService } from '@app/layout/services/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent {
  showLoader: any;

  constructor(private loaderService: LoaderService, private ngZone: NgZone, private router: Router) {
    this.loaderService.loading$.subscribe((val: boolean) => {
      this.ngZone.run(() => {
        const currentUrl = this.router.url;

        // disabling universal loader on view-reports screen
        if (currentUrl === '/reports/view-reports' || currentUrl === '/data/calculations') {
          this.showLoader = false;
        } else {
          this.showLoader = val;
        }
      });
    });
  }
}
