<div [style.width.px]="width">
  {{ content ? content : 'N/A' }}

  <!-- btn to show full content -->
  <ng-container *ngIf="content.length > limit">
    <a class="expanded" role="button" (click)="toggleContent()">
      <span *ngIf="!isContentToggled">
        <i class="pi pi-chevron-down"></i>
      </span>
      <span *ngIf="isContentToggled">
        <i class="pi pi-chevron-up"></i>
      </span>
    </a>
  </ng-container>
</div>
