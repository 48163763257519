import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { CredentialsService } from '../services/credentials.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private credentialsService: CredentialsService, private translateService: TranslateService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const requestCopy = request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.credentialsService.authToken}`,
        'Accept-Language': this.translateService.currentLang, // Set Accept-Language header
      },
    });

    return next.handle(requestCopy);
  }
}
