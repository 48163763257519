import { HttpClient, HttpHandler, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Base64 } from 'js-base64';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { LoginRequest, LoginResponse } from '@app/authentication/interfaces/login.element';
import { CredentialsService } from '@app/core/services/credentials.service';
import { LayoutService } from '@app/layout/services/layout.service';

const routes = {
  login: `${environment.SLMUrl}/users/authenticate`,
  permissions: `${environment.SLMUrl}/users/current/details`,
  refreshToken: `${environment.SLMUrl}/users/refreshToken`,
};

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private isRefreshingToken = false;

  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private httpClient: HttpClient,
    private credentialsService: CredentialsService,
    private router: Router,
    private layoutService: LayoutService
  ) {}

  login(context: LoginRequest): Observable<LoginResponse> {
    // encode password to base64
    const encodedPassword = Base64.encode(context.password);
    const bodyInfo = new HttpParams().set('userName', context.userName.trim()).set('password', encodedPassword);

    return this.httpClient
      .skipTokenInjection()
      .post(
        routes.login,

        bodyInfo.toString(),

        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      )
      .pipe(
        map((body: any) => {
          this.credentialsService.setCredentials(body, context.remember);

          return body;
        }),
        catchError((err: unknown) => {
          throw err;
        })
      );
  }

  permissions(localstorageUsed?: boolean): Observable<any> {
    return this.httpClient.get(routes.permissions, {}).pipe(
      map((body: any) => {
        this.credentialsService.setPermissions(body.permissions, localstorageUsed);

        return body.permissions;
      }),
      catchError((err: unknown) => {
        throw err;
      })
    );
  }

  logout(): Observable<boolean> {
    // Customize credentials invalidation here
    this.credentialsService.setCredentials();

    return of(true);
  }

  refreshToken(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;
      this.refreshTokenSubject.next(null);

      const credentials: any = sessionStorage.getItem('credentials');
      const token = JSON.parse(credentials).refresh_token;
      const bodyInfo = new HttpParams().set('refresh_token', token);

      if (token) {
        return this.httpClient
          .skipTokenInjection()
          .post(routes.refreshToken, bodyInfo)
          .pipe(
            switchMap((res: any) => {
              this.isRefreshingToken = false;

              this.credentialsService.resetCredentials();
              this.credentialsService.setCredentials(res);
              this.refreshTokenSubject.next(res.access_token);

              return next.handle(this.addTokenHeader(request, res.access_token));
            }),
            catchError((err: unknown) => {
              this.isRefreshingToken = false;

              const isSessionTimeoutVisible = true;

              this.layoutService.sessionTimeoutRightSidebar.next(isSessionTimeoutVisible);
              throw err;
            })
          );
      }
    }

    return this.refreshTokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      switchMap(token => next.handle(this.addTokenHeader(request, token)))
    );
  }

  addTokenHeader(request: HttpRequest<any>, token: string): any {
    return request.clone({ headers: request.headers.set('Authorization', `Bearer ${token}`) });
  }

  sessionExpiryIn(): number | undefined {
    let credentials: any = sessionStorage.getItem('credentials');

    if (credentials !== null) {
      credentials = parseInt(JSON.parse(credentials).expires_in, 10);

      return credentials;
    }
  }
}
