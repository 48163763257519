import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent {
  data: any = null;

  private metadata: any = {
    'not-found': {
      code: 404,
      title: 'Requested page not found',
      description: "If you're sure that such page exists - please contact your administator",
    },
    forbidden: {
      code: 403,
      title: 'Forbidden',
      description:
        "You don't have required permissions to view the page. Please click the button below to request them.",
    },
  };

  constructor(private route: ActivatedRoute, private location: Location) {
    const path = this.route.snapshot?.routeConfig?.path;

    if (path) {
      this.data = this.metadata[path];
    }
  }

  getBack(): void {
    this.location.back();
  }
}
