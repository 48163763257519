import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { environment } from '../../../environments/environment';

const routes = {
  organization: `${environment.SLMUrl}/hierarchies/getTreeByHierarchyName/Organizations`,
  hierarchies: `${environment.SLMUrl}/hierarchies/info`,
  hierarchyNode: `${environment.SLMUrl}/hierarchies`,
};

@Injectable({
  providedIn: 'root',
})
export class OrganizationService {
  hierarchyData = new BehaviorSubject<any>('');

  constructor(private httpClient: HttpClient) {}

  hierarchyManagementData(data: any): void {
    this.hierarchyData.next(data);
  }

  getMetricTreeData(): any {
    return this.httpClient.get(routes.organization);
  }

  getHierarchies(): any {
    return this.httpClient.get(routes.hierarchies);
  }

  getHierarchyNodeData(hierarchyId: number, nodeId: number): any {
    return this.httpClient.get(`${routes.hierarchyNode}/${hierarchyId}/nodeinfo/${nodeId}`);
  }
}
