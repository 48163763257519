import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CellRendererService {
  constructor(private datePipe: DatePipe) {}

  // common cell rendering
  reportCellRenderer(params: any): any {
    const mainSpan: any = document.createElement('span');

    mainSpan.style.display = 'block';
    // mainSpan.style.minWidth = '200px';
    mainSpan.style.height = 'inherit';
    const img: any = document.createElement('img');

    img.className = 'cursor-pointer';

    mainSpan.innerHTML = params.value ? params.value : '';
    if (params.column.colId === 'target') {
      mainSpan.innerHTML = '';
      this.getTargetCellData(params, mainSpan, img);
    }

    if (params.colDef.headerName === 'Status' || params.colDef.headerName === 'Version Status') {
      mainSpan.innerHTML = '';
      this.statusCellData(params, mainSpan);
    }

    if (params.colDef.field === 'coverage') {
      mainSpan.innerHTML = '';
      this.coverageCellData(params, mainSpan, img);
    }

    if (params.data.attributes.length) {
      params.data.attributes.forEach((property: any) => {
        if (params.column.colId === property.field) {
          // Replace spaces in the "name" field with hyphens and convert it to lowercase for the object key
          const key = property.name?.toLowerCase().replace(/\s+/g, '-');

          // Assign the "key" field value to the object key and the "value" field to the object value
          mainSpan.style[key] = property.key?.toLowerCase();
          if (key === 'border-color') {
            mainSpan.style['border-style'] = 'solid';
          }

          // display as a hyperlink
          if (property.name === 'Drill To Webform Add' || property.name === 'Drill To Webform Edit') {
            mainSpan.style['text-decoration'] = 'underline';
            mainSpan.role = 'button';
          }

          // get the icon and cell value
          switch (property.key) {
            case 'UPLOAD_FILE':
              img.src = `assets/icons/upload.svg`;
              img.alt = property.key?.toLowerCase();
              mainSpan.innerHTML = property.field === 'action' ? '' : mainSpan.innerHTML;
              mainSpan.appendChild(img);
              break;
            case 'ADD_ROW':
              {
                const addIcon = document.createElement('i');

                addIcon.className = 'pi pi-plus-circle p-1 align-middle';
                mainSpan.appendChild(addIcon);
              }
              break;
            case 'EDIT_ROW':
              {
                const editIcon = document.createElement('i');

                editIcon.className += 'pi pi-file-edit p-1 align-middle';
                mainSpan.appendChild(editIcon);
              }
              break;
            default:
              return mainSpan;
          }

          return mainSpan;
        }
      });
    }

    return mainSpan;
  }

  getTargetCellData(params: any, mainSpan: any, imgTag: any): any {
    const span = mainSpan;
    const img = imgTag;

    img.className += ' icon-class';
    img.alt = params.key?.toLowerCase();

    const targetValue: any = document.createElement('span');

    targetValue.innerHTML = params.value ? params.value : '';

    switch (params.data.condition) {
      case '>':
        img.src = `assets/icons/greater-than.svg`;
        span.appendChild(img);
        span.appendChild(targetValue);
        break;
      case '>=':
        img.src = `assets/icons/greater-than-equals.svg`;
        span.appendChild(img);
        span.appendChild(targetValue);
        break;
      case '<':
        img.src = `assets/icons/less-than.svg`;
        span.appendChild(img);
        span.appendChild(targetValue);
        break;
      case '<=':
        img.src = `assets/icons/less-than-equals.svg`;
        span.appendChild(img);
        span.appendChild(targetValue);
        break;
      default:
        return span;
    }

    return span;
  }

  coverageCellData(params: any, mainSpan: any, imgTag: any): any {
    const span = mainSpan;
    const img = imgTag;

    img.className += ' icon-class';
    img.alt = params.key?.toLowerCase();

    const coverageValue: any = document.createElement('span');

    const paramValue = params.value ? params.value : '';

    coverageValue.innerHTML = params.value ? params.value : '';

    switch (paramValue) {
      case 'Current':
        img.src = 'assets/icons/metrics/current.svg';
        break;
      case 'Not Current':
      case 'History':
      case 'Future':
        img.src = 'assets/icons/metrics/not current.svg';
        break;
      case 'N/A':
      case 'Not Available':
        img.src = 'assets/icons/metrics/na.svg';
        break;
      default:
        return span;
    }

    span.appendChild(img);
    span.appendChild(coverageValue);

    return span;
  }

  statusCellData(params: any, mainSpan: any): any {
    const span = mainSpan;

    const paramValue = params.value ? params.value : '';

    // Removing any existing classes before adding a new one
    span.className = '';
    span.style.display = 'inline';

    // Add the appropriate class based on the value
    switch (paramValue) {
      case 'Published':
      case 'Completed':
        span.className = 'success-chip';
        break;
      case 'Revoked':
      case 'Not Published':
      case 'Failed':
        span.className = 'danger-chip';
        break;
      case 'Draft':
      case 'In Progress':
        span.className = 'warning-chip';
        break;
      default:
        return span;
    }

    span.innerHTML = paramValue;

    return span;
  }

  UploadCellRenderer(params: any): any {
    const mainSpan: any = document.createElement('span');

    mainSpan.style.display = 'block';
    mainSpan.style.height = 'inherit';
    mainSpan.innerHTML = params.value || params.value === 0 ? params.value : 'N/A';
    if (params.colDef.headerName === 'Status') {
      mainSpan.innerHTML = '';
      this.statusIconCellData(params, mainSpan);
    }
    if (
      params.colDef.headerName === 'Last Uploaded' ||
      params.colDef.headerName === 'Started At' ||
      params.colDef.headerName === 'Finished At'
    ) {
      mainSpan.innerHTML = params.value ? this.datePipe.transform(params.value, 'yyyy-MM-dd H:mm:ss') : 'N/A';
    }
    if (params.colDef.headerName === 'History') {
      mainSpan.innerHTML = '';
      const addIcon = document.createElement('i');

      addIcon.className = 'pi pi-link text-info-emphasis clickable-icon cursor-pointer';
      mainSpan.appendChild(addIcon);
    }
    if (params.colDef.headerName === 'Download' || params.colDef.headerName === 'Action') {
      mainSpan.innerHTML = '';
      const addIcon = document.createElement('i');

      addIcon.className = 'pi pi-download text-info-emphasis clickable-icon cursor-pointer';
      mainSpan.appendChild(addIcon);
    }

    if (
      params.colDef.headerName === 'Failed Rows' &&
      (params.data.status === 'PARTIAL_SUCCESS' || params.data.status === 'FAILED')
    ) {
      const addIcon = document.createElement('i');

      addIcon.className = 'pi pi-download text-info-emphasis clickable-icon cursor-pointer';
      addIcon.style.marginLeft = '5px';
      mainSpan.appendChild(addIcon);
    }

    return mainSpan;
  }

  statusIconCellData(params: any, mainSpan: any): any {
    const addIcon = document.createElement('i');

    switch (params.value) {
      case 'SUBMITTED':
        addIcon.className = 'pi pi-check text-info-emphasis';
        break;
      case 'IN_PROGRESS':
        addIcon.className = 'pi pi-spin pi-hourglass text-info-emphasis';
        break;
      case 'PARTIAL_SUCCESS':
        addIcon.className = 'pi pi-exclamation-circle text-warning-emphasis';
        break;
      case 'SUCCESS':
        addIcon.className = 'pi pi-check text-success';
        break;
      case 'FAILED':
        addIcon.className = 'pi pi-exclamation-triangle text-danger';
        break;
      default:
        break;
    }

    mainSpan.appendChild(addIcon);

    return mainSpan;
  }
}
