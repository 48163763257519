<app-loader></app-loader>

<div class="layout-wrapper" [ngClass]="containerClass">
  <app-topbar></app-topbar>
  <app-menu></app-menu>
  <div class="layout-main-container">
    <div class="layout-main layout-pos">
      <app-breadcrumb></app-breadcrumb>
      <router-outlet></router-outlet>
    </div>
  </div>
  <!-- <app-config></app-config> -->
  <div class="layout-mask"></div>
</div>

<!-- Session-Expired right sidebar -->
<p-sidebar
  [(visible)]="isSessionTimeoutVisible"
  position="right"
  styleClass="p-sidebar-md"
  [showCloseIcon]="false"
  [dismissible]="false"
  [closeOnEscape]="false"
>
  <app-session-expired *ngIf="isSessionTimeoutVisible"></app-session-expired>
</p-sidebar>
<!-- primeng toast message -->
<p-toast
  [showTransformOptions]="'translateX(100%)'"
  [showTransitionOptions]="'100ms'"
  [hideTransitionOptions]="'100ms'"
  [hideTransformOptions]="'translateX(100%)'"
  [preventOpenDuplicates]="true"
></p-toast>
