import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';

import { CredentialsService } from '../services/credentials.service';

@Injectable()
export class NegateAuthenticationGuard implements CanActivate {
  constructor(private router: Router, private credentialsService: CredentialsService) {}

  canActivate(): boolean {
    if (!this.credentialsService.isAuthenticated()) {
      return true;
    }

    this.router.navigate(['/welcome'], {
      replaceUrl: true,
    });

    return false;
  }
}
