import { Injectable } from '@angular/core';

import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class MenuPermissionsService {
  constructor() {}

  isNotTSLA(): boolean {
    if (
      environment.name === 'dev' ||
      environment.name === 'qa' ||
      environment.name === 'sirius' ||
      environment.name === 'demo'
    ) {
      return true;
    }

    return false;
  }
}
