<div class="right-sidebar-header">
  <h5 class="px-4 fw-bold d-inline" translate>MODULES.CONTRACTS.DETAILS</h5>
  <p-divider styleClass="mb-0"></p-divider>
</div>

<div class="px-4">
  <div class="row mt-2">
    <!--Contract Id -->
    <div class="col-3 col-sm-3 fw-bold" translate>
      MODULES.CONTRACTS.CONTRACT_ID
    </div>
    <div class="col-9 col-sm-9">
      {{ viewData?.contractNo }}
      <p-skeleton *ngIf="!viewData?.contractNo"></p-skeleton>
    </div>

    <!--Contract name-->
    <div class="col-3 col-sm-3 fw-bold" translate>
      MODULES.VIEW_METRICS.CONTRACT_NAME
    </div>
    <div class="col-9 col-sm-9">
      {{ viewData?.contractName }}
      <p-skeleton *ngIf="!viewData?.contractName"></p-skeleton>
    </div>

    <!--Contract status-->
    <div class="col-3 col-sm-3 fw-bold" translate>
      MODULES.VIEW_METRICS.CONTRACT_STATUS
    </div>
    <div class="col-9 col-sm-9">
      <span *ngIf="viewData?.status" [ngClass]="
          viewData?.status === 'Published'
            ? 'success-chip'
            : viewData?.status === 'Draft'
            ? 'warning-chip'
            : 'danger-chip'
        ">
        {{ viewData?.status }}
      </span>
      <p-skeleton *ngIf="!viewData?.status"></p-skeleton>
    </div>

    <!--Description-->
    <div class="col-3 col-sm-3 fw-bold" translate>
      MODULES.VIEW_METRICS.DESCRIPTION
    </div>
    <div class="col-9 col-sm-9">
      {{ viewData?.description }}
      <p-skeleton *ngIf="!viewData?.description"></p-skeleton>
    </div>

    <!--Customer-->
    <div class="col-3 col-sm-3 fw-bold" translate>MODULES.METRICS.CUSTOMER</div>
    <div class="col-9 col-sm-9">
      {{ viewData?.customerName }}
      <p-skeleton *ngIf="!viewData?.customerName"></p-skeleton>
    </div>

    <!--Provider-->
    <div class="col-3 col-sm-3 fw-bold" translate>MODULES.METRICS.PROVIDER</div>
    <div class="col-9 col-sm-9">
      {{ viewData?.providerName }}
      <p-skeleton *ngIf="!viewData?.providerName"></p-skeleton>
    </div>
  </div>
</div>

<h5 class="mt-4 px-4" translate>MODULES.CONTRACTS.EDIT_VERSION_DETAILS</h5>
<p-divider></p-divider>

<ng-container *ngIf="!viewVersionList?.length">
  <div class="px-4">
    <div class="row">
      <div class="col-12 col-sm-12">
        <p-skeleton></p-skeleton>
      </div>
      <div class="col-12 col-sm-12">
        <p-skeleton></p-skeleton>
      </div>
      <div class="col-12 col-sm-12">
        <p-skeleton></p-skeleton>
      </div>
      <div class="col-12 col-sm-12">
        <p-skeleton></p-skeleton>
      </div>
      <div class="col-12 col-sm-12">
        <p-skeleton></p-skeleton>
      </div>
    </div>
  </div>
</ng-container>

<!-- Adding version details -->
<form [formGroup]="editVersionForm" *ngIf="viewVersionList?.length">
  <div class="px-4">
    <div class="row">
      <!-- version name -->
      <div class="col-12 col-sm-12">
        <ng-container *ngIf="viewVersionList.length">
          <button pButton type="button" class="w-full p-button-outlined custom-btn-outline" (click)="op.toggle($event)">
            <div class="w-100 text-center">
              <span class="pe-2">{{
                selectedVersion
                ? selectedVersion.version
                : viewVersionList[0].version
                }}
              </span>
              <span [ngClass]="
                  selectedVersion.status === 'Published'
                    ? 'success-chip'
                    : selectedVersion?.status === 'Draft'
                    ? 'warning-chip'
                    : 'danger-chip'
                ">
                {{
                selectedVersion
                ? selectedVersion.status
                : viewVersionList[0].status
                }}
              </span>
            </div>
            <i class="pi pi-angle-down"></i>
          </button>

          <p-overlayPanel #op [showCloseIcon]="true" styleClass="custom-overlay">
            <ng-template pTemplate>
              <div class="mb-3">
                <span class="fw-bold pe-3 ps-2 align-middle" translate>MODULES.VIEW_METRICS.FILTER_BY</span>
                <ng-container formArrayName="statusFilter" *ngFor="let check of statusList; let i = index">
                  <p-checkbox [label]="check.label" [binary]="true" class="align-middle px-2"
                    [labelStyleClass]="check.class" [formControlName]="i"
                    (onChange)="applyStatusFilter($event, check.label)">
                  </p-checkbox>
                </ng-container>
              </div>

              <p-table #paginationReset styleClass="p-datatable-sm" [value]="filteredVersion" selectionMode="single"
                [(selection)]="selectedVersion" (onRowSelect)="onClosePanel($event)"
                (onRowUnselect)="onClosePanel($event)" [paginator]="true" [rows]="10" [showCurrentPageReport]="true"
                currentPageReportTemplate="{{
                'MODULES.PAGINATION.SHOWING' | translate }} {first} {{ 'MODULES.PAGINATION.TO' | translate }} {last}
                {{ 'MODULES.PAGINATION.OF' | translate }} {{ filteredVersion.length }}
                {{ 'MODULES.PAGINATION.ENTRIES' | translate }}" [scrollHeight]="'calc(100vh - 180px)'">
                <ng-template pTemplate="header">
                  <tr>
                    <th style="width: 110px" translate>
                      MODULES.VIEW_METRICS.VERSION_NAME
                    </th>
                    <th style="width: 90px" translate>
                      MODULES.METRICS.STATUS
                    </th>
                    <th style="width: 100px" translate>
                      MODULES.VIEW_METRICS.START_DATE
                    </th>
                    <th style="width: 100px" translate>
                      MODULES.VIEW_METRICS.END_DATE
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-version>
                  <tr [pSelectableRow]="rowData">
                    <td>{{ version.version }}</td>
                    <td>
                      <span [ngClass]="
                          version.status === 'Published'
                            ? 'success-chip'
                            : version.status === 'Draft'
                            ? 'warning-chip'
                            : 'danger-chip'
                        ">
                        {{ version.status }}
                      </span>
                    </td>
                    <td>{{ version.startDate | date : 'yyyy-MM-dd' }}</td>
                    <td>{{ version.endDate | date : 'yyyy-MM-dd' }}</td>
                  </tr>
                </ng-template>
                <!-- no records msg -->
                <ng-template pTemplate="emptymessage">
                  <tr>
                    <td class="text-center" [attr.colSpan]="4">
                      {{ 'MODULES.EMPTY_TABLE_MSG' | translate }}
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </ng-template>
          </p-overlayPanel>
        </ng-container>
      </div>

      <!-- input field -->
      <div class="col-12 col-sm-12 mt-1">
        <span class="w-100 p-float-label my-1">
          <input class="w-100 p-inputtext-sm" type="text" pInputText formControlName="versionName" />
          <label for="target">{{
            'MODULES.VIEW_METRICS.VERSION_NAME' | translate
            }}</label>
        </span>

        <div *ngIf="editVersionForm.get('versionName')?.touched">
          <small *ngIf="editVersionForm.get('versionName')?.errors?.['maxlength']" class="text-danger">
            {{ 'VALIDATION.MAXLENGTH' | translate : '{requiredLength: 20}' }}
          </small>
        </div>
      </div>

      <!-- start date -->
      <div class="col-12">
        <span class="w-100 p-float-label my-1">
          <p-calendar styleClass="w-100" formControlName="startDate" [showIcon]="true" dateFormat="dd-mm-yy"
            [minDate]="minStartDate" (onSelect)="onSelectStartDate($event)" [readonlyInput]="true" appendTo="body">
          </p-calendar>
          <label for="startDate">{{
            'MODULES.CONTRACTS.START_DATE' | translate
            }}</label>
        </span>
      </div>

      <!-- end date -->
      <div class="col-12">
        <span class="w-100 p-float-label my-1">
          <p-calendar styleClass="w-100" formControlName="endDate" [showIcon]="true" [minDate]="minEndDate"
            [maxDate]="maxEndDate" dateFormat="dd-mm-yy" [readonlyInput]="true" [defaultDate]="minEndDate"
            appendTo="body">
          </p-calendar>
          <label for="endDate">{{
            'MODULES.CONTRACTS.END_DATE' | translate
            }}</label>
        </span>
      </div>

      <!-- statement -->
      <div class="col-12">
        <span class="w-100 p-float-label my-1">
          <textarea class="w-100" pInputTextarea formControlName="statement"></textarea>
          <label for="statement">{{
            'MODULES.CONTRACTS.STATEMENT' | translate
            }}</label>
        </span>

        <div *ngIf="editVersionForm.get('statement')?.touched">
          <small *ngIf="editVersionForm.get('statement')?.errors?.['maxlength']" class="text-danger">
            {{ 'VALIDATION.MAXLENGTH' | translate : '{requiredLength: 250}' }}
          </small>
        </div>
      </div>

      <!-- api error msg block -->
      <ng-container *ngIf="errorMsgList?.length">
        <ul class="ps-3">
          <li class="text-danger" *ngFor="let msg of errorMsgList">
            {{ msg }}
          </li>
        </ul>
      </ng-container>
    </div>

    <!-- btn section -->
    <div class="row mt-2">
      <div class="col-4 col-sm-4">
        <button pButton type="button" label="{{ 'MODULES.CONTRACTS.CANCEL' | translate }}" class="p-button-outlined"
          style="width: 110px" (click)="resetForm()"></button>
      </div>
      <div class="col-4 col-sm-4 text-center" *ngIf="selectedVersion.status === 'Draft'">
        <button pButton type="submit" label="{{ 'MODULES.CONTRACTS.SAVE' | translate }}"
          [disabled]="!contractPermission.hasEditContractVersion()" class="p-button" style="width: 110px"
          (click)="saveNewVersion(viewData.id)"></button>
      </div>
      <div class="col-4 col-sm-4 text-right" *ngIf="selectedVersion.status === 'Draft'">
        <button pButton type="submit" label="{{ 'MODULES.CONTRACTS.PUBLISH' | translate }}"
          [disabled]="!contractPermission.hasPublishContractVersion()" class="p-button" style="width: 110px"
          (click)="cofirmPublish($event)"></button>
      </div>
      <div class="col-8 col-sm-8 text-right" *ngIf="selectedVersion.status === 'Published'">
        <button pButton type="submit" label="{{ 'MODULES.CONTRACTS.REVOKE' | translate }}"
          [disabled]="!contractPermission.hasRevokeContractVersion()" class="p-button" style="width: 110px"
          (click)="cofirmRevoke($event)"></button>
      </div>
    </div>
  </div>
</form>

<p-confirmPopup></p-confirmPopup>