import { Component, OnInit } from '@angular/core';

import { Metrics } from '@app/slm/interface/metrics.tree';
import { OrganizationService } from '@app/slm/services/organization.service';

@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.scss'],
})
export class OrganizationComponent implements OnInit {
  metricTreeData!: Metrics[];

  hierarchiesData: any = [];

  selected: any = [];

  metricsTableData: any = [];

  hierarchieName!: string;

  isViewOrganization = false;

  constructor(private metricService: OrganizationService) {
    this.metricsTableData = [];
  }

  ngOnInit(): void {
    this.getMetricTreeData();
    this.getHierarchies();
  }

  getHierarchies(): void {
    this.metricService.getHierarchies().subscribe((res: any) => {
      if (res) {
        this.hierarchiesData = res;
      }
    });
  }

  getMetricTreeData(): void {
    this.metricService.getMetricTreeData().subscribe((res: any) => {
      if (res) {
        this.metricTreeData = [res];

        // select root node
        [this.selected] = this.metricTreeData;

        this.hierarchieName = this.selected.name;

        // expand root node
        this.selected.expanded = true;
      }
    });
  }

  nodeSelect(): void {
    // expand-collapse on node
    if (this.selected.key !== '0') {
      this.selected.expanded = !this.selected.expanded;
    }

    // to check last node
    if (this.selected.children && this.selected.children.length === 0) {
      this.selected.expanded = !this.selected.expanded;
    }
  }

  getOrganizationData(): void {
    this.isViewOrganization = !this.isViewOrganization;

    const managementDetails = this.hierarchiesData.find((item: any) => this.hierarchieName === item.name);

    this.metricService.hierarchyManagementData({ nodeInfo: this.selected, managementDetails });
  }
}
