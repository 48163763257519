import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';

const routes = {
  about: `${environment.SLMUrl}/about`,
};

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  constructor(private httpClient: HttpClient) {}

  getAbout(): any {
    return this.httpClient.get(routes.about);
  }
}
