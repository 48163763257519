import { Routes, Route } from '@angular/router';

import { LayoutComponent } from '@app/layout/layout.component';
import { AuthenticationGuard } from 'src/app/core/guards/authentication.guard';

/**
 * Provides helper methods to create routes.
 */
export class ShellService {
  /**
   * Creates routes using the shell component and authentication.
   * @param routes The routes to add.
   * @return The new route using shell as the base.
   */
  static headerSidebarRoutes(routes: Routes): Route {
    return {
      path: '',
      component: LayoutComponent,
      children: routes,
      data: {
        breadcrumb: 'BREADCRUMBS.HOME',
        icon: 'home',
      },
      canActivate: [AuthenticationGuard],
    };
  }

  static headerOnlyRoutes(routes: Routes): Route {
    return {
      path: '',
      component: LayoutComponent,
      data: {
        isSidebarHidden: true,
      },
      children: routes,
      canActivate: [AuthenticationGuard],
    };
  }
}
