import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { LazyLoadEvent, TreeNode } from 'primeng/api';
import { Table } from 'primeng/table';

import { Metrics } from '@app/slm/interface/metrics.tree';
import { ContractService } from '@app/slm/services/contract.service';

@Component({
  selector: 'app-choose-catalog',
  templateUrl: './choose-catalog.component.html',
  styleUrls: ['./choose-catalog.component.scss'],
})
export class ChooseCatalogComponent implements OnInit {
  @ViewChild('paginationReset') paginationReset!: Table;

  @Input() save = false;

  @Input() contractId = 0;

  @Output() closeClicked = new EventEmitter<boolean>();

  metricTreeData!: Metrics[];

  selected: any = [];

  metricsTableData: any = [];

  businessId = '';

  page = 0;

  size = 50;

  totalPages = 0;

  totalRecords = 0;

  showBackToTopButton = false;

  selectedTableRow: any = [];

  isRowSelected = true;

  loading = false;

  entityList: any = [];

  metricConnectorParamId!: number;

  providerForm!: FormGroup;

  constructor(private contractService: ContractService) {}

  getCatalogTreeData(): void {
    const params = {
      status: 'Published',
      coverage: 'Current',
    };

    this.loading = true;

    this.contractService.getCatalogTreeData(params).subscribe((res: any) => {
      if (res) {
        this.metricTreeData = [res];

        // select root node
        [this.selected] = this.metricTreeData;

        // expand root node
        this.selected.expanded = true;

        const childrenData = res?.children;

        childrenData.forEach((node: TreeNode<any>) => {
          this.disableRecursive(node);
        });
        this.loading = false;
      }
    });

    this.getProviderEntity();
  }

  nodeSelect(): void {
    // expand-collapse on node
    if (this.selected.key !== '0') {
      this.selected.expanded = !this.selected.expanded;
    }

    // to check last node
    if (this.selected.children && this.selected.children.length === 0) {
      this.selected.expanded = !this.selected.expanded;
    }
    this.businessId = this.selected.businessId;
    const { children } = this.selected;

    this.page = 0;
    this.showBackToTopButton = false;

    if (children.length === 0) {
      const params = { page: this.page, size: this.size, statuses: 'Published', coverages: 'Current' };

      this.paginationReset?.reset();
      this.getMetrics(this.businessId, params);
      this.metricsTableData = [];
    }
  }

  getMetrics(businessId: string, params: any = {}): void {
    this.contractService.getCatalogTableData(businessId, params).subscribe((res: any) => {
      if (res) {
        this.metricsTableData = res.elements;
        this.totalPages = res.totalPages;
        this.totalRecords = res.totalElements;
      }
    });
  }

  getProviderEntity(): void {
    this.contractService.getProviderEntityList().subscribe((res: any) => {
      if (res) {
        this.metricConnectorParamId = res.metricConnectorParamId;
        this.entityList = res.providers;

        if (this.entityList?.length) {
          this.providerForm = new FormGroup({
            selectedEntity: new FormControl(null),
          });
        }
      }
    });
  }

  ngOnInit(): void {
    this.getCatalogTreeData();
  }

  triggerPagination(event?: LazyLoadEvent): void {
    if (event && event.first !== undefined && event.rows !== undefined && event.rows > 0) {
      this.page = Math.floor(event.first / event.rows);
      this.size = event.rows;
      const params = { page: this.page, size: this.size, statuses: 'Published', coverages: 'Current' };

      if (this.metricsTableData?.length) {
        this.getMetrics(this.businessId, params);
      }
    }
  }

  copyBtnDisable(): boolean {
    if (
      this.selectedTableRow.length &&
      this.entityList?.length &&
      this.providerForm?.get('selectedEntity')?.value?.length
    ) {
      return false;
    }
    if (this.selectedTableRow.length && !this.entityList?.length) {
      return false;
    }

    return true;
  }

  copyMetrics(): void {
    const selectedMetricId = this.selectedTableRow.map((item: any) => item.id);
    let providerEntity: any;

    if (this.providerForm?.get('selectedEntity')?.value?.length) {
      providerEntity = {
        metricConnectorParamId: this.metricConnectorParamId,
        providers: this.providerForm.get('selectedEntity')?.value,
      };
    }

    if (this.save && this.contractId) {
      this.contractService
        .updateMatricCatlogIds(this.contractId, { metricCatalogIds: selectedMetricId, providerEntity })
        .subscribe(() => {
          this.contractService.getSelectedMetricList({ metricCatalogIds: selectedMetricId, providerEntity });
          this.closeClicked.emit(false);
        });
    } else {
      this.contractService.getSelectedMetricList({ metricCatalogIds: selectedMetricId, providerEntity });
      this.closeClicked.emit(false);
    }
  }

  closePopup(): void {
    this.closeClicked.emit(false);
  }

  private disableRecursive(node: any): void {
    const nodeSelected = node;

    if (!node.countOfChildren) {
      nodeSelected.selectable = false;
    }
    node.children.forEach((childNode: any) => {
      this.disableRecursive(childNode);
    });
  }
}
