import { DatePipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';

import { UdfComponent } from '@app/shared/components/udf/udf.component';
import { MetricPermissionService } from '@app/slm/services/metric-permission.service';
import { MetricsService } from '@app/slm/services/metrics.service';
import { UdfService } from '@app/slm/services/udf.service';

@Component({
  selector: 'app-add-version',
  templateUrl: './add-version.component.html',
  styleUrls: ['./add-version.component.scss'],
})
export class AddVersionComponent implements OnInit {
  @Output() closeClicked = new EventEmitter<boolean>();

  @ViewChild(UdfComponent) udfComponent!: UdfComponent;

  metricSubscription!: Subscription;

  createVersionForm!: FormGroup;

  minStartDate = new Date('01-01-1901');

  maxEndDate = new Date('12-31-9999');

  minEndDate!: Date;

  viewData: any = [];

  formGroup!: FormGroup;

  rowId!: number;

  errorMsgList: any = [];

  UDFVersionList: any;

  metricVersionObjectFields: any;

  constructor(
    private metricService: MetricsService,
    private datePipe: DatePipe,
    private messageService: MessageService,
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    public metricPermission: MetricPermissionService,
    private confirmationService: ConfirmationService,
    private udfService: UdfService
  ) {}

  ngOnInit(): void {
    this.createVersionForm = this.formBuilder.group({
      versionName: ['', [Validators.maxLength(20)]],
      target: [],
      expected: [],
      startDate: [''],
      endDate: [''],
      metricVersionStatement: [null, [Validators.maxLength(250)]],
    });

    this.metricService.isSelectedVersion$.subscribe((res: any) => {
      if (res) {
        this.viewData = res.viewData;
      }
    });

    this.createVersionForm.get('expected')?.disable();
    this.getUDFVersionData();
  }

  saveNewVersion(id: number): void {
    this.errorMsgList = [];
    if (this.createVersionForm.invalid || this.udfComponent.dynamicForm.invalid) {
      this.createVersionForm.markAllAsTouched();
    } else {
      const startDate = this.datePipe.transform(this.createVersionForm.get('startDate')?.value, 'yyyy-MM-dd');
      const endDate = this.datePipe.transform(this.createVersionForm.get('endDate')?.value, 'yyyy-MM-dd');

      const basePayload = {
        versionName: this.createVersionForm.get('versionName')?.value,
        target: this.createVersionForm.get('target')?.value,
        expected: this.createVersionForm.get('expected')?.value,
        startDate: startDate ? new Date(startDate).toISOString() : '',
        endDate: endDate ? new Date(endDate).toISOString() : '',
        metricVersionStatement: this.createVersionForm.get('metricVersionStatement')?.value,
      };
      const payload = {
        ...basePayload,
        ...(this.metricVersionObjectFields && this.metricVersionObjectFields.length > 0
          ? { objectFields: this.metricVersionObjectFields }
          : {}),
      };

      this.metricService.createNewVersion(id, payload).subscribe(
        (res: any) => {
          if (res) {
            this.metricService.getCreatedMetricData({ res });
            this.messageService.add({
              severity: 'success',
              summary: this.translateService.instant('AUTH.DEFAULT_SUCCESS_SUMMARY'),
              detail: this.translateService.instant('MODULES.CREATE_METRIC.VERSION_SUCCESS', {
                metric: this.viewData?.metricName,
                version: res.versionName,
              }),
            });
            this.resetForm();
            this.metricService.toogleSidebar(false);
          }
        },
        (err: any) => {
          if (err && Array.isArray(err)) {
            const errorList: any = [];

            err.forEach((msg: any) => {
              errorList.push(msg.message);
            });
            this.errorMsgList = errorList;
          }
        }
      );
    }
  }

  publishVersion(id: any): void {
    const startDate = this.datePipe.transform(this.createVersionForm.get('startDate')?.value, 'yyyy-MM-dd');
    const endDate = this.datePipe.transform(this.createVersionForm.get('endDate')?.value, 'yyyy-MM-dd');

    const basePayload = {
      versionName: this.createVersionForm.get('versionName')?.value,
      target: this.createVersionForm.get('target')?.value,
      expected: this.createVersionForm.get('expected')?.value,
      startDate: startDate ? new Date(startDate).toISOString() : '',
      endDate: endDate ? new Date(endDate).toISOString() : '',
      metricVersionStatement: this.createVersionForm.get('metricVersionStatement')?.value,
    };
    const payload = {
      ...basePayload,
      ...(this.metricVersionObjectFields && this.metricVersionObjectFields.length > 0
        ? { objectFields: this.metricVersionObjectFields }
        : {}),
    };

    this.metricService.publishVersion(id, payload, false).subscribe(
      (res: any) => {
        if (res) {
          this.metricService.getCreatedMetricData({ res });
          this.messageService.add({
            severity: 'success',
            summary: this.translateService.instant('AUTH.DEFAULT_SUCCESS_SUMMARY'),
            detail: this.translateService.instant('MODULES.CREATE_METRIC.VERSION_PUBLISH', {
              metric: this.viewData?.metricName,
              version: res.versionName,
            }),
          });
          this.resetForm();
          this.metricService.toogleSidebar(false);
        }
      },
      (err: any) => {
        if (err && Array.isArray(err)) {
          const errorList: any = [];

          err.forEach((msg: any) => {
            errorList.push(msg.message);
          });
          this.errorMsgList = errorList;
        }
      }
    );
  }

  cofirmPublish(event: Event): void {
    this.errorMsgList = [];

    if (!this.createVersionForm.get('startDate')?.value || !this.createVersionForm.get('endDate')?.value) {
      const errorList: any = [];
      const msg = {
        message: this.translateService.instant('MODULES.VIEW_METRICS.ERROR'),
        fieldName: 'Date',
      };

      errorList.push(msg.message);

      this.errorMsgList = errorList;
      this.createVersionForm.markAllAsTouched();
    } else if (this.createVersionForm.invalid || this.udfComponent.dynamicForm.invalid) {
      this.createVersionForm.markAllAsTouched();
    } else {
      this.confirmationService.confirm({
        target: event.target ? event.target : undefined,
        icon: 'pi pi-exclamation-triangle',
        message: this.translateService.instant('MODULES.CONTRACTS.CONFIRM_PUBLISH'),
        acceptLabel: this.translateService.instant('MODULES.CONTRACTS.YES'),
        rejectLabel: this.translateService.instant('MODULES.CONTRACTS.NO'),
        rejectButtonStyleClass: 'p-button-outlined',
        accept: () => {
          this.publishVersion(this.viewData.id);
        },
      });
    }
  }

  resetForm(): void {
    this.createVersionForm.reset();
    this.minEndDate = new Date();
    this.viewData = [];
    this.errorMsgList = [];
    this.closeClicked.emit(false);
    this.metricService.getAddVersionInfo(false);
  }

  onSelectStartDate(event: any): void {
    this.minEndDate = new Date();
    this.minEndDate = new Date(event.getFullYear(), event.getMonth(), event.getDate() + 1);
    this.createVersionForm.get('endDate')?.reset();
  }

  getTagetValue(): void {
    if (this.createVersionForm.get('target')?.value) {
      this.createVersionForm.get('expected')?.enable();
    } else {
      this.createVersionForm.get('expected')?.disable();
      this.createVersionForm.patchValue({ expected: null });
    }
  }

  /** UDf */

  onFormDataChanged(formData: any): void {
    /**
     * get keys on transmissiona and then assign that value
     * Along with the keys
     * */

    if (formData.metricVersionObjectFields && formData.metricVersionObjectFields.length > 0) {
      this.metricVersionObjectFields = formData.metricVersionObjectFields.filter((obj: any) => {
        // Check if both 'value' and 'valueSetKey' properties are not null
        if (
          (obj.value && obj.value.length > 0) ||
          typeof obj.value === 'number' ||
          (obj.valueSetKey && obj.valueSetKey.length > 0)
        ) {
          return obj.value || obj.valueSetKey;
        }

        // Default return statement
        return false;
      });
    }
  }

  getUDFVersionData(): void {
    const params = {
      type: 'MetricVersion',
    };

    this.metricService.getUDFInfo(params).subscribe((res: any) => {
      if (res && res.length > 0) {
        this.UDFVersionList = res;
        this.udfService.getUDFType(res);
      }
    });
  }
}
