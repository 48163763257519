import { Component, OnInit } from '@angular/core';

import { PrimeNGConfig } from 'primeng/api';

import { I18nService } from './i18n';
import { environment } from '@env/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(private i18nService: I18nService, private primengConfig: PrimeNGConfig) {}

  ngOnInit(): void {
    // primeng ripple
    this.primengConfig.ripple = true;

    // Setup translations
    this.i18nService.init(environment.defaultLanguage, environment.supportedLanguages);
  }
}
