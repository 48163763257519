import { Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';

import { LayoutService } from '../services/layout.service';
import { CredentialsService } from '@app/core/services/credentials.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent {
  @ViewChild('topbarmenu') menu!: ElementRef;

  @ViewChild('menubutton') menuButton!: ElementRef;

  @ViewChild('topbarmenubutton') topbarMenuButton!: ElementRef;

  open = false;

  isViewEnabled = false;

  isAboutEnabled = false;

  logoutMenu: MenuItem[] = [
    {
      label: 'Logout',
      icon: 'pi pi-power-off',
      id: 'SEL_LOGOUT',
      command: () => {
        this.logoutConfirmation();
      },
    },
  ];

  constructor(
    public layoutService: LayoutService,
    public credentialsService: CredentialsService,
    private router: Router,
    private translate: TranslateService
  ) {}

  redirectToHome(): void {
    this.router.navigate(['/welcome']);
  }

  goToHelpCenter(): void {
    const lang = localStorage.getItem('language');

    if (lang === 'de-DE') {
      const helpUrl = 'https://www.amasol.de/service-level-support';

      window.open(helpUrl, '_blank');
    } else {
      const helpUrl = 'https://www.amasol.de/service-level-support';

      window.open(helpUrl, '_blank');
    }
  }

  logoutConfirmation(): void {
    this.open = !this.open;
    this.isViewEnabled = !this.isViewEnabled;
  }

  viewAboutDetails(): void {
    this.isAboutEnabled = !this.isAboutEnabled;
  }

  goToLegal(): void {
    let legalUrl: any;

    if (this.translate.currentLang === 'en-US') {
      legalUrl = 'https://www.amasol.de/en/imprint';
    } else {
      legalUrl = 'https://www.amasol.de/impressum';
    }

    window.open(legalUrl, '_blank');
  }
}
